import Cookies from 'js-cookie';
import axios from 'axios';
import { restUrl, unregisteredProfileCookie, googleIDTokenCookie, googleRefreshTokenCookie } from '../../env';

export let loggedInUsername = undefined;
export let loggedInTokenData = undefined;
export let loggedInKarmaScore = undefined;
export let loggedInKarmaTotal = undefined;
export let numUsernameChangesLeft = undefined;

let onLoggedInCallbacks = [];
let onUsernameChangedCallbacks = [];

export async function WaitUntilLoggedIn( loggedInCallback )
{
	if( loggedInTokenData )
	{
		// Already logged in! Callback immediately
		await loggedInCallback();
	}
	else
	{
		onLoggedInCallbacks.push( loggedInCallback );
	}
}

export function RegisterOnUsernameChangeCallback( onChangeCallback )
{
	if( onUsernameChangedCallbacks.includes( onChangeCallback ) )
	{
		// This callback method is already registered
		console.log( "RegisterOnUsernameChangeCallback() - This callback method is already registered" );
		return;
	}

	onUsernameChangedCallbacks.push( onChangeCallback );
}

export function UnregisterOnUsernameChangeCallback( onChangeCallback )
{
	let index = onUsernameChangedCallbacks.indexOf( onChangeCallback );

	if( index >= 0 )
	{
		onUsernameChangedCallbacks.splice( index, 1 );
	}
	else
	{
		console.log("UnregisterOnUsernameChangeCallback() Couldn't find callback registered...");
	}
}


async function CallOnLoggedInCallbacks()
{
	for( let idx=0; idx < onLoggedInCallbacks.length; idx++)
  {
  	await onLoggedInCallbacks[idx]();
  }

  onLoggedInCallbacks = [];
}

function CallOnUsernameChangedCallbacks( newUsername )
{
	for( let idx=0; idx < onUsernameChangedCallbacks.length; idx++)
  {
  	onUsernameChangedCallbacks[idx]( newUsername );
  }
}

export async function AttemptUserLoginFlow( loginCallback )
{
	// Check for a registered google account token
	let idToken = Cookies.get( googleIDTokenCookie );

	if( idToken )
	{
	  let refreshToken = Cookies.get( googleRefreshTokenCookie );

	  const verifiedGoogleResponse = await verifyGoogleTokenOnServer(idToken, refreshToken);

	  if(verifiedGoogleResponse)
	  {
	  	const username = verifiedGoogleResponse.loggedInUsername;
	    const tokenData = verifiedGoogleResponse.tokenData;
	    const karmaScore = verifiedGoogleResponse.karmaScore;
	    const karmaTotal = verifiedGoogleResponse.karmaTotal;
	    const usernameChangesLeft = verifiedGoogleResponse.usernameChangesLeft;

	    if (username)
	    {
	      if(tokenData)
	      {
	        // Set latest access token cookie state
	        Cookies.set( googleIDTokenCookie, tokenData.tokenId );
	      }

	      loggedInUsername = username;
	      loggedInTokenData = tokenData;
	      loggedInKarmaScore = karmaScore;
	      loggedInKarmaTotal = karmaTotal;
	      numUsernameChangesLeft = usernameChangesLeft;

	      loginCallback( { isGoogleAssociated: true } );

      	await CallOnLoggedInCallbacks();

	      return;
	    }
	  }

	  // Else
	  // Google login failed.
	  // Fall through to non-reg account login check. User can always re-login via the Google Login button


	  // Should force clear the googleRefreshTokenCookie here, as user has a google login token, but wasn't able to login/refresh their login token. Need a new refresh token.
	  Cookies.remove( googleRefreshTokenCookie );
	}


	// Check for an unregistered account token
	let nonRegToken = Cookies.get( unregisteredProfileCookie );

	if( nonRegToken )
	{
	  const verifiedResponse = await verifyLocalTokenOnServer(nonRegToken);

	  if (verifiedResponse)
	  {
	  	const username = verifiedResponse.loggedInUsername;
	    const tokenData = verifiedResponse.tokenData;
	    const karmaScore = verifiedResponse.karmaScore;
	    const karmaTotal = verifiedResponse.karmaTotal;
	    const usernameChangesLeft = verifiedResponse.usernameChangesLeft;

	    if(username)
	    {
	      console.log("Existing User login username : ", username );

	      loggedInUsername = username;
	      loggedInTokenData = tokenData;
	      loggedInKarmaScore = karmaScore;
	      loggedInKarmaTotal = karmaTotal;
	      numUsernameChangesLeft = usernameChangesLeft;

	      loginCallback( { isGoogleAssociated: false} );

	      await CallOnLoggedInCallbacks();
	    }
	    
	    return;
	  }
	}

	// Else create new unregistered account
	
	// This will setup a token with a randomly generated username, but NOT store anything in the database yet.
	// The client will store this token in a cookie and use it from then on.

	try
	{
		const response = await axios.post(`${restUrl}/auth/loginNew`); 

		if (!response || !response.data.success)
		{
			// return this.setState({ isLoginFailed: true });
			loginCallback( { error: 'Failed to create a new login.' } );
			return;
		}

		const {username, tokenData, karmaScore, karmaTotal, usernameChangesLeft} = response.data;

		// Cache the login token for future quick retrieval
		Cookies.set( unregisteredProfileCookie, tokenData.tokenId );

		console.log("New login username : ", username);

		loggedInUsername = username;
		loggedInTokenData = tokenData;
		loggedInKarmaScore = karmaScore;
    loggedInKarmaTotal = karmaTotal;
    numUsernameChangesLeft = usernameChangesLeft;

		loginCallback( { success: true, isGoogleAssociated: false } );

		await CallOnLoggedInCallbacks();

		return;
	}
	catch (error)
	{
	  console.log(error.message);
	  loginCallback( { error: error.message } );
	}
}


export async function LoginWithGoogle( googleData, loginCallback )
{
	// console.log( "onGoogleLogin ", googleData );

	if( !loggedInTokenData )
	{
		// Login with Google attempted before a non-social account login has completed
		console.log("Login with Google attempted before a non-social account login has completed. Failing login");
		loginCallback( false );

		return;
	}

	const userToken = loggedInTokenData.tokenId;

	let refreshToken = Cookies.get( googleRefreshTokenCookie );

	const response = await axios.post(`${restUrl}/auth/googleLogin`, { googleCode: googleData.code, unregisteredAccountLoginToken: userToken, refreshToken: refreshToken });

	if (!response || !response.data.success)
	{
		console.log("GoogleLogin auth fail. Error: ", response.data.error );
		// Server side google login failed...

		loginCallback( false );

		return;
	}

	// Google login success.

	const {username, tokenData, karmaScore, karmaTotal, usernameChangesLeft} = response.data;

	// Login user with the google token and associated account username
	Cookies.set( googleIDTokenCookie, tokenData.tokenId );
	Cookies.set( googleRefreshTokenCookie, tokenData.refreshToken );
	// Cookies.remove( unregisteredProfileCookie );

	loggedInUsername = username;
	loggedInTokenData = tokenData;
	loggedInKarmaScore = karmaScore;
  loggedInKarmaTotal = karmaTotal;
  numUsernameChangesLeft = usernameChangesLeft;

	console.log("Google login success");

	loginCallback( true );

	await CallOnLoggedInCallbacks();
}

export async function LogoutGoogle()
{
	console.log("On Google Logout");

	// Clear google login token ID cookie
	Cookies.remove( googleIDTokenCookie );
}

export async function ChangeUsername( newUsername )
{
	// console.log("ChangeUsername() newUsername", newUsername);

	const response = await axios.post(`${restUrl}/auth/changeUsername`, { tokenData: loggedInTokenData, newUsername: newUsername });

	if (!response)
	{
		return { success: false, error: "Server error" };
	}

	if (!response.data.success)
	{
		return response.data;
	}

	const {username, tokenData, karmaScore, karmaTotal, usernameChangesLeft} = response.data;

	// console.log("Updated login username : ", username);

	loggedInUsername = username;
	loggedInTokenData = tokenData;
	loggedInKarmaScore = karmaScore;
  loggedInKarmaTotal = karmaTotal;
  numUsernameChangesLeft = usernameChangesLeft;

  CallOnUsernameChangedCallbacks( loggedInUsername );

	return { success: true };
};




const verifyLocalTokenOnServer = async token =>
{
  const response = await axios.post(`${restUrl}/auth/verify`, { token });

  if (response && response.data.success)
  {
    return { 
    					loggedInUsername: response.data.username,
              tokenData: response.data.tokenData,
              karmaScore: response.data.karmaScore,
              karmaTotal: response.data.karmaTotal,
              usernameChangesLeft: response.data.usernameChangesLeft
            };
  }

  return undefined;
};

const verifyGoogleTokenOnServer = async ( idToken, refreshToken ) =>
{
  const response = await axios.post(`${restUrl}/auth/verifyGoogle`, { idToken, refreshToken });

  if (response && response.data.success)
  {
    return {  loggedInUsername: response.data.username,
              tokenData: response.data.tokenData,
              karmaScore: response.data.karmaScore,
              karmaTotal: response.data.karmaTotal,
              usernameChangesLeft: response.data.usernameChangesLeft
            };
  }

  return undefined;
};






