import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import Button from '../Utils/Button';

export default class JoinRoom extends React.Component
{
  onJoinRoom = event => {
    event.preventDefault();

    // Process the forms input data to get the room ID
    const formData = new FormData(event.target);
    const formDataObj = Object.fromEntries(formData.entries());

    const roomId = formDataObj.roomId;

    const { onJoinRoom } = this.props;

    onJoinRoom( { roomId } );
  };

  render()
  {
    const { rooms } = this.props;

    return ( 
      <div>

        <h2>Rooms</h2>
        <Button onClick={() =>
        {
          this.props.updateRoomsList();
        }}>
          Refresh
        </Button>

        <br />
        <br />

        <ul>
          {rooms.map(el => (
            <li key={ el.roomId + "" } id={ el.roomID + "" }>
              <div>
                <Form onSubmit={this.onJoinRoom}>

                  <InputGroup>

                    {/*Room { el.roomID }*/}

                    { el.players } / { el.maxPlayers } Players
                    {" "}
                    --
                    {" "}
                    {/*Show Host PLayer Name*/}
                    { el.name }

                    

                    <input
                      type="hidden"
                      value={ el.roomId }
                      name="roomId"
                    />

                    {" -- "}

                    <InputGroup.Append>
                      <button type="submit">
                        { el.rejoinable ? "Rejoin" : "Join" }
                      </button>
                    </InputGroup.Append>
                  </InputGroup>
                </Form>
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}
