import axios from 'axios';
import { restUrl } from '../../env';
import { loggedInTokenData } from './LoginManager'

export let isAdminUser = undefined;

export async function AttemptAdminPortalLoginFlow( loginCallback )
{

	if( !loggedInTokenData )
	{
		loginCallback( { success: false, error: "User not logged in yet." } );
		return;
	}

  const response = await axios.post(`${restUrl}/auth/verifyAdmin`, { tokenData: loggedInTokenData });

  if( !response )
  {
  	isAdminUser = false;
  	loginCallback( { success: false, error: "Server error." } );
  }
  else if( !response.data.success )
  {
  	isAdminUser = false;
  	loginCallback( { success: false, error: response.data.error } );
  }
  else
  {
  	isAdminUser = true;
  	loginCallback( { success: true } );
  }
}

