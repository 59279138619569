import React from 'react';
import { useLocation } from "react-router-dom";
import Profile from '../Profile/Profile';

export default function ProfileRouter(props)
{
  let location = useLocation();

  let profileName = location.pathname.substring( 9 );

  if( location.hash )
  {
    profileName = profileName + location.hash;
  }

  return (
    <Profile profileName={profileName} />
  );
}
