import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { loggedInTokenData } from '../Core/LoginManager';
import { gameStateManager } from '../../App';
import { Navigate } from "react-router-dom";
import SliderOption from '../Utils/SliderOption';

export default class BugReportRow extends React.Component {
  state = {
    skipToTileIndex: 0,
    skipToFeatureResolveIndex: 0,
    playbackTurnSpeed: 2
  };

  onDebugPlayback = event =>
  {
    event.preventDefault();

    // Process the forms input data to get the room ID
    const formData = new FormData(event.target);
    const formDataObj = Object.fromEntries(formData.entries());

    // console.log("onDebugPlayback - formDataObj : ", formDataObj);

    const {
      bugReportId
    } = formDataObj;

    const { skipToTileIndex, skipToFeatureResolveIndex, playbackTurnSpeed } = this.state;

    console.log("skipToTileIndex : " + skipToTileIndex, "skipToFeatureResolveIndex : ", skipToFeatureResolveIndex, "playbackTurnSpeed", playbackTurnSpeed);

    gameStateManager.createBugReportPlaybackSocket( loggedInTokenData,
                                                    bugReportId,
                                                    () => { this.forceUpdate(); },
                                                    this.onServerErrorReceived,
                                                    skipToTileIndex,
                                                    skipToFeatureResolveIndex,
                                                    playbackTurnSpeed );
  };

  onServerErrorReceived = errorData =>
  {
    console.log("onServerErrorReceived - ", errorData);
  }

  render()
  {

    if ( gameStateManager.socketConnectedRoomId )
    {
      return (
        <Navigate to={"/#" + gameStateManager.socketConnectedRoomId } replace={true} />
      );
    }


    let data = this.props.bugReportData;

    return ( 

      <div>
        <Form onSubmit={this.onDebugPlayback}>

          <InputGroup>

            { data.roomId }

            {" "}
            --
            {" "}

            { data.numPlayers } Players

            {" "}
            --
            {" "}

            { data.numTilePlacements } Moves
            
            {" "}
            --
            {" "}

            { data.dateReported }

            <input
              type="hidden"
              value={ data.bugReportId }
              name="bugReportId"
            />

            {" -- "}

            <SliderOption
              title="Skip To Tile:"
              min={0}
              max={data.numTilePlacements}
              value = { 0 }
              onValueChange={ (newValue) => { this.setState( { 'skipToTileIndex' : newValue } ); } }
            />

            <SliderOption
              title="Skip To End Game Feature Resolve:"
              min={0}
              max={50}
              value = { 0 }
              onValueChange={ (newValue) => { this.setState( { 'skipToFeatureResolveIndex' : newValue } ); } }
            />

            <SliderOption
              title="Play Speed (Seconds):"
              min={0}
              max={10}
              value = { this.state.playbackTurnSpeed }
              onValueChange={ (newValue) => { this.setState( { 'playbackTurnSpeed' : newValue } ); } }
            />

            <InputGroup.Append>
              <button type="submit">
                Debug
              </button>
            </InputGroup.Append>
          </InputGroup>
        </Form>
      </div>

    );
  }
}
