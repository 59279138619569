import React from 'react';
import UserProfilePanel from './UserProfilePanel';
import Footer from './Footer';
import NavigateSpan from '../Utils/NavigateSpan';
import PopupHandler from '../Utils/PopupHandler';

export default class PageContainerWithLogo extends React.Component
{
  render()
  {
    return (
      <>
          <PopupHandler />

          <div className="top-bar">
            <NavigateSpan href={"/"}>
              <img src={process.env.PUBLIC_URL + '/meeples-io-logo.png'} className="meeples-logo" alt="Meeples Logo" />
            </NavigateSpan>

            <div className="top-bar-menu">
              <span>Home</span>
              <span>Store</span>
            </div>

            <UserProfilePanel />

            <div className="clear-both" />

          </div>

          <div className="main-container-block-with-ads">
            <div className="lobby-vertical-ad"><img src={process.env.PUBLIC_URL + '/fake-ads/ad1.jpg'} alt="Ad banner" /></div>

            <div className="main-container-block">
              {this.props.children}
            </div>

            <div className="lobby-vertical-ad"><img src={process.env.PUBLIC_URL + '/fake-ads/ad2.jpg'} alt="Ad banner" /></div>
          </div>

          <Footer />
      </>
    );
  }
}
