import React from 'react';
import MessageList from './MessageList';

import { gameStateManager } from '../../App';

class GameInfoConsole extends React.Component
{
  state = {
    messages: []
  };

  scrollToBottom = false;

  constructor(props)
  {
    super(props);

    // subscribeTo.showPlayers((message) => {
    //   let convertedMsg = '';
    //   message.playersJoined.forEach(player => {
    //     convertedMsg += `${player.username} is ${
    //       player.isReady ? 'ready' : 'not ready'
    //     } \n`;
    //   });
    //   const systemMsg = {
    //     position: 'right',
    //     notch: false,
    //     date: null,
    //     type: 'text',
    //     text: convertedMsg
    //   };
    //   this.setState(state => ({
    //     messages: [...state.messages, systemMsg]
    //   }));
    //   console.log(message);
    // });

    gameStateManager.addOnGameInfoConsoleMessageCallback( (message) =>
    {
      const messageData =
      {
        text: message
      };
      this.setState(state => ({ messages: [...state.messages, messageData] }));
    });
    
    // TODO: Re-add
    
    // subscribeTo.roomPlayerTurnStart((data) =>
    // {
    //   const messageData =
    //   {
    //     text: "*******************************************"
    //   };
    //   this.setState(state => ({ messages: [...state.messages, messageData] }));
    // });



    // subscribeTo.myTilePlacementTurnStart((message) => {
    //   const systemMsg = {
    //     position: 'right',
    //     notch: false,
    //     date: null,
    //     type: 'text',
    //     text: message
    //   };

    //   this.setState(state => ({ messages: [...state.messages, systemMsg] }));
    //   console.log(message);
    // });

    // subscribeTo.roomPlayerTurnStart((data) => {
    //   const systemMsg = {
    //     position: 'right',
    //     notch: false,
    //     date: null,
    //     type: 'text',
    //     text: data.message
    //   };

    //   this.setState(state => ({ messages: [...state.messages, systemMsg] }));
      
    //   console.log(data.message);
    // });

    // subscribeTo.myTilePlacementTurnEnd((message) => {
    //   const systemMsg = {
    //     position: 'right',
    //     notch: false,
    //     date: null,
    //     type: 'text',
    //     text: message
    //   };

    //   this.setState(state => ({ messages: [...state.messages, systemMsg] }));
    //   console.log(message);
    // });

    // subscribeTo.playerTurnEnd((message) => {
    //   const systemMsg = {
    //     position: 'right',
    //     notch: false,
    //     date: null,
    //     type: 'text',
    //     text: message
    //   };

    //   this.setState(state => ({ messages: [...state.messages, systemMsg] }));
    //   console.log(message);
    // });

    // subscribeTo.onGameEnd((message) => {
    //   const systemMsg = {
    //     position: 'right',
    //     notch: false,
    //     date: null,
    //     type: 'text',
    //     text: message
    //   };

    //   this.setState(state => ({ messages: [...state.messages, systemMsg] }));
    //   console.log(message);
    // });
  }

  render() {
    const { messages } = this.state;

    const
    {
      stageRenderData
    } = this.props;

    return (
      <div style={{
          width: (stageRenderData.scale * 331) + "px",
          height: (stageRenderData.scale * 200) + "px",
          position: "absolute",
          backgroundColor: '#fdf4e0',
          right: ((window.innerWidth - stageRenderData.width) / 2) + "px",
          top: stageRenderData.stageStyleTop + "px"
        }}>
        
          <MessageList
            messages={messages}
            containerClassName="game-info-console"
            messageLineClassName="game-info-console-line"
          />
        
      </div>
    );
  }
}

export default GameInfoConsole;
