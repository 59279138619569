import React from 'react';
import Button from '../Utils/Button';
import { Row, Col } from 'react-bootstrap';
import { emit } from '../Socket/game.Emitters';

class SubmitBugPopup extends React.Component
{
  constructor(props) {
    super(props);

    this.state = { textValue: "..." };

    this.handleTextChange = this.handleTextChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.submit = props.submit;
  }

  handleTextChange(event)
  {
    this.setState({textValue: event.target.value});
  }

  handleSubmit()
  {
    console.log('A bug was submitted: ' + this.state.textValue);

    emit.submitBugReport( this.state.textValue );

    this.props.close();

    this.submit( this.state.textValue );
  }

  render()
  {
    return (

      <div className="panel">
        <span>
          <h3>Submit A Bug Report</h3>
        </span>

        <form onSubmit={this.handleSubmit}>
          <label>
            What happened?
          </label>
          <div>
            <input
              type="text"
              style={{ width: "100%", marginBottom: "20px" }}
              value={this.state.textValue}
              onChange={this.handleTextChange} />
          </div>
          
        </form>

        <Row>

          <Col>
            <Button onClick={this.props.close} style={{display: 'block', marginLeft: 'auto', marginRight: 'auto' }} >
              Cancel
            </Button>
          </Col>
          <Col>
            <Button onClick={this.handleSubmit} style={{display: 'block', marginLeft: 'auto', marginRight: 'auto' }} >
              Submit
            </Button>
          </Col>

        </Row>

      </div>
    );
  }
}

export default SubmitBugPopup;
