import { dispatch } from './PopupStore';

let popupIndex = 0;

const createPopup = (
  popupElement
) => ({
  createdAt: Date.now(),
  visible: true,
  popupElement,
  pauseDuration: 0,
  id: 'popup_' + (++popupIndex),
});

const createHandler =
  () =>
  (popupElement) =>
  {
    const popupData = createPopup(popupElement);
    dispatch({ type: 'ADD_POPUP', popup: popupData });
    return popupData.id;
  };

const popup = (popupElement) =>
  createHandler()(popupElement);

popup.remove = (popupId) =>
  dispatch({ type: 'REMOVE_POPUP', popupId });


export { popup };