import React, { useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import { Room, Lobby } from '../../components';
import toast from 'react-hot-toast';

import { AttemptUserLoginFlow, loggedInTokenData } from '../Core/LoginManager'

export default function RoomRouter(props)
{
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  let location = useLocation();
  let navigate = useNavigate();

  let gameStateManager = props.gameStateManager;

  if( location.hash )
  {
    // Check if hash is a valid hash.

    if( isRoomHashValid( location.hash ) )
    {
      const urlRoomId = location.hash.substring(1);

      if( gameStateManager.socketConnectedRoomId )
      {
        // Client has been added to a room already
        // Check that the connected room ID matches the URL hash

        if( gameStateManager.socketConnectedRoomId === urlRoomId )
        {
          return (
            <Room />
          );
        }
        else
        {
          return ( <div>Attempting to join a Room {urlRoomId} but still associated with Room {gameStateManager.socketConnectedRoomId}</div> );
        }
      }
      else
      {
        // Client is attempting to join a room with this hash.
        // Check if the room hash matches an existing room ID.

        if(!loggedInTokenData)
        {
          // User hasn't logged in yet; must've opened up a fresh browser tab with this room URL
          // Need to log them in first

          AttemptUserLoginFlow(( loginResponseData ) =>
          {
            console.log("Room URL flow. loginResponseData", loginResponseData );

            forceUpdate();
          });
        }
        else
        {
          gameStateManager.joinExistingRoomSocket( loggedInTokenData, urlRoomId,
                                  ( roomId ) =>
                                  {
                                    // on room connect success
                                    console.log("Room connect success.");

                                    forceUpdate();
                                  },
                                  (errorData) =>
                                  {
                                    // on room connect fail
                                    console.log("Room connect failed");

                                    // Show error popup and return to main lobby
                                    // setError( error );

                                    navigate( "/" , { replace: true });

                                    toast.error(errorData.content);

                                  } ); 
        }

        
        

        return ( <div>Attempting to join existing Room {location.hash}</div> );
      }
    }
  }

  return (
    <Lobby />
  );
}

function isRoomHashValid( roomHash )
{
  // check for starting hashtag symbol
  let hashIndex = roomHash.indexOf( '#' );

  if(hashIndex !== 0)
  {
    return false;
  }

  // Check there aren't any other hashes
  hashIndex = roomHash.indexOf( '#', 1 );

  if(hashIndex >= 0 )
  {
    // console.log("format error: found hash at index " + hashIndex);
    return false;
  }

  if( roomHash.length !== 6)
  {
    // console.log( "format error: wrong length." );
    return false;
  }

  return true;
}
