import React from 'react';
import PixiScaledSprite from "./PixiScaledSprite"
import PixiScaledText from "./PixiScaledText"
import { Container } from '@inlet/react-pixi';

class PixiEndGamePlayerInfoRow extends React.Component
{
	textStyle = {
	  align: "center",
	  fontWeight: "normal",
	  fill: "#111111"
	};

	render()
	{
		const stageRenderData = this.props.stageRenderData;
		const score = this.props.playerScore;
		const isClientPlayerRow = this.props.isClientPlayerRow;
		const isHuman = this.props.isHuman;
		const teamColour = this.props.teamColour;
		const finishingPosition = this.props.finishingPosition;

		return (

			<Container>
				<PixiScaledSprite
					imageSrc={"./ui/panel.png"}
					isNineSlice={true}
					cornerRadius={15}
					tint={ isClientPlayerRow ? 0xffffff : 0xbbbbbb }
					stageRenderData={stageRenderData}
					x= { this.props.x }
					y= { this.props.y }
					width = {this.props.width}
					height = {this.props.height}
					anchor={ {x: 1, y: 1} } />

				<PixiScaledText
					text={ this.props.playerName }
					stageRenderData={ stageRenderData }
					x= { this.props.x - this.props.width + 185 }
					y= { this.props.y - (this.props.height / 2) }
					anchor={ {x: 0.5 , y: 0.5 }}
					style={this.textStyle}
				/>

				<PixiScaledText
					text={ score }
					stageRenderData={ stageRenderData }
					x= { this.props.x - this.props.width + 325 }
					y= { this.props.y - (this.props.height / 2) }
					anchor={ {x: 0.5 , y: 0.5 }}
					style={this.textStyle}
					fontSize={25}
				/>

				<PixiScaledText
					text={ finishingPosition }
					stageRenderData={ stageRenderData }
					x= { this.props.x - this.props.width + 415 }
					y= { this.props.y - (this.props.height / 2) }
					anchor={ {x: 0.5 , y: 0.5 }}
					style={this.textStyle}
					fontSize={25}
				/>

				<PixiScaledSprite
					imageSrc={ "./ui/circle-white.png" }
					stageRenderData={stageRenderData}
					x= { this.props.x - this.props.width + 55 }
					y= { this.props.y - (this.props.height / 2) }
					tint={ teamColour }
					width = {40}
					height = {40}
					anchor={ {x: 0.5, y: 0.5} } /> 

				<PixiScaledSprite
					imageSrc={ isHuman ? "./ui/human-icon.png" : "./ui/bot-icon.png" }
					stageRenderData={stageRenderData}
					x= { this.props.x - this.props.width + 55 }
					y= { this.props.y - (this.props.height / 2) }
					width = {35}
					height = {35}
					anchor={ {x: 0.5, y: 0.5} } />

			</Container>

		);
	}
}

export default PixiEndGamePlayerInfoRow;