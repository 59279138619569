import React from 'react';
import Button from '../Utils/Button';

class ConfirmationPopup extends React.Component
{
  render()
  {
    return (

      <div>
        <h1>
          {this.props.title}
        </h1>
        <div>
          {this.props.body}
        </div>

        <Button
          style={{ fontSize: 10, margin: "4px auto 4px auto", width: '50%' }}
          onClick={() =>
          {
            this.props.onConfirm();
            this.props.close();
          }}
        >
          {this.props.buttonText}
        </Button>

      </div>
    );
  }
}

export default ConfirmationPopup;
