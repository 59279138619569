import React from 'react';
import RoomSetup from './RoomSetup';
import ErrorHandler from '../ErrorHandler';
import PixiGame from '../PixiGame/PixiGame';
import GameInfoConsole from "../Core/GameInfoConsole"
import OverlayHelpButtons from "../Core/OverlayHelpButtons"
import { gameStateManager } from '../../App';

export default class Room extends React.Component
{

  stageWidthRef = 1350;
  stageHeightRef = 845; //833;

  state = {
    warning: [true, '', ''],
    isReady: false,
    playersInRoom: [],
    error: '',

    stageWidth: 1350, // 1350   2700
    stageHeight: 833, // 833    1666    
    renderScale: 1.0,

    stageRenderData: { width: 1350, height: 833, scale: 1.0, refWidth: 1350, refHeight: 833, stageStyleTop: '-14px' },
  };

  onGameStartCallbackHandle = null;
  onRoomErrorCallbackHandle = null;

  constructor(props)
  {
    super(props);

    window.addEventListener('resize', this.updateStageScaling );
  }

  componentDidMount()
  {
    this.updateStageScaling();
  
    this.onGameStartCallbackHandle = () =>
    {
      console.log("ROOM onGameStartCallbackHandle");

      // Force a re-render
      this.forceUpdate();
    };

    gameStateManager.onGameStartRegister( this.onGameStartCallbackHandle );

    this.onRoomErrorCallbackHandle = (errorData) =>
    {
      console.log("Room onRoomError", errorData);

      this.setState({ error: errorData });
    };

    gameStateManager.onRoomErrorRegister( this.onRoomErrorCallbackHandle );
  }

  componentWillUnmount()
  {
    gameStateManager.onGameStartUnregister( this.onGameStartCallbackHandle );
    gameStateManager.onRoomErrorUnregister( this.onRoomErrorCallbackHandle );

    this.onGameStartCallbackHandle = null;
    this.onRoomErrorCallbackHandle = null;
  }



  updatePlayers = playersInRoom =>
  {
    this.setState({ playersInRoom });
  };

  resetError = () =>
  {
    this.setState({ error: '' });
  };

  updateStageScaling = () =>
  {
    let includeAdBanners = true;

    if( process.env.REACT_APP_DISABLE_AD_BANNERS )
    {
      includeAdBanners = false;
    }

    // console.log("window resized. " + window.innerWidth + "x" + window.innerHeight +  " , includeAdBanners : " + includeAdBanners);

    const bannerAdSpacingWidth = 175;
    const leftBannerWidth = includeAdBanners ? bannerAdSpacingWidth : 0;
    const rightBannerWidth = includeAdBanners ? bannerAdSpacingWidth : 0;

    const stageStyleTopRef = -14;

    let stageWidthRef = this.state.stageRenderData.refWidth;
    let stageHeightRef = this.state.stageRenderData.refHeight;
    let newStageWidth = stageWidthRef;
    let newStageHeight = stageHeightRef;
    let newStageStyleTop = stageStyleTopRef;

    if( window.innerWidth < newStageWidth + leftBannerWidth + rightBannerWidth )
    {
      newStageWidth = window.innerWidth - leftBannerWidth - rightBannerWidth;
      newStageHeight = newStageWidth * ( stageHeightRef / stageWidthRef );
    }

    if( window.innerHeight < newStageHeight )
    {
      newStageHeight = window.innerHeight;
      newStageWidth = newStageHeight * ( stageWidthRef / stageHeightRef );
    }
    else
    {
      // Want to position stage vertically in the middle of the window
      newStageStyleTop += (window.innerHeight - newStageHeight) / 2;
    }

    this.setState( { stageRenderData:
              {   
                width: newStageWidth,
                height: newStageHeight,
                scale: (newStageWidth / stageWidthRef),
                refWidth : this.state.stageRenderData.refWidth,
                refHeight : this.state.stageRenderData.refHeight,
                stageStyleTop : newStageStyleTop
              }
            });

  };


  render()
  {
    const {
      error,
      stageRenderData,
    } = this.state;

    if (error)
    {
      return <ErrorHandler redirectUrl="/" error={error} resetError={this.resetError} />;
    }

    // console.log("ROOM RENDER.");

    if( !gameStateManager.socketRoomHasStarted )
    {
      return (
        <RoomSetup />
      );
    }

    let includeAdBanners = true;

    if( process.env.REACT_APP_DISABLE_AD_BANNERS )
    {
      includeAdBanners = false;
    }

    const clientSocketID = gameStateManager.socketClientID;

    return (
      <div className="main-container-block-with-ads" >
        { includeAdBanners && ( <div className="in-game-ad-left"><img src={process.env.PUBLIC_URL + '/fake-ads/ad1.jpg'} alt="Ad banner" /></div> ) }

        <PixiGame clientId={clientSocketID} stageRenderData={stageRenderData} />

        { includeAdBanners && ( <div className="in-game-ad-right"><img src={process.env.PUBLIC_URL + '/fake-ads/ad2.jpg'} alt="Ad banner" /></div> ) }

        <GameInfoConsole stageRenderData={stageRenderData} />

        <OverlayHelpButtons stageRenderData={stageRenderData} />
       
      </div>
    );
  }
}
