import React from 'react';
import PixiScaledSprite from "./PixiScaledSprite"
import PixiScaledText from "./PixiScaledText"
import { Container } from '@inlet/react-pixi';

class PixiGamePlayerRow extends React.Component
{
	textStyle = {
	  align: "center",
	  fontWeight: "normal",
	  fill: "#111111"
	};

	render()
	{
		const stageRenderData = this.props.stageRenderData;
		const score = this.props.playerScore;
		const isClientPlayerRow = this.props.isClientPlayerRow;
		const teamColour = this.props.teamColour;
		const nameScoreSpacing = isClientPlayerRow ? 30 : 27;

		let meeplesStates = [];
		let xOffset = 125;

		for ( let idx=0; idx < this.props.playerMeeples.length; idx++ )
		{
			meeplesStates.push(

				<PixiScaledSprite
					key={ idx.toString() }
					imageSrc={ "./ui/meeple-standard.png" }
					stageRenderData={ stageRenderData }
					x= { this.props.x - this.props.width + xOffset }
					y= { this.props.y - (this.props.height / 2) }
					tint={ teamColour }
					width = {27}
					height = {27}
					anchor={ {x: 0.5, y: 0.5} } /> 
			);
			
			xOffset += 27;
		}


		return (

			<Container>
				<PixiScaledSprite
					imageSrc={"./ui/panel.png"}
					isNineSlice={true}
					cornerRadius={15}
					tint={ isClientPlayerRow ? 0xffffff : 0xbbbbbb }
					stageRenderData={stageRenderData}
					x= { this.props.x }
					y= { this.props.y }
					width = {this.props.width}
					height = {this.props.height}
					anchor={ {x: 1, y: 1} } />

				<PixiScaledText
					text={ this.props.playerName }
					stageRenderData={ stageRenderData }
					x= { this.props.x - this.props.width + 55 }
					y= { this.props.y - (this.props.height / 2) - nameScoreSpacing }
					anchor={ {x: 0.5 , y: 0.5 }}
					style={this.textStyle}
				/>

				<PixiScaledText
					text={ score }
					stageRenderData={ stageRenderData }
					x= { this.props.x - this.props.width + 55 }
					y= { this.props.y - (this.props.height / 2) + nameScoreSpacing }
					anchor={ {x: 0.5 , y: 0.5 }}
					style={this.textStyle}
					fontSize={15}
				/> 

				<PixiScaledSprite
					imageSrc={ "./ui/circle-white.png" }
					stageRenderData={stageRenderData}
					x= { this.props.x - this.props.width + 55 }
					y= { this.props.y - (this.props.height / 2) }
					tint={ teamColour }
					width = {40}
					height = {40}
					anchor={ {x: 0.5, y: 0.5} } /> 

				<PixiScaledSprite
					imageSrc={ this.props.playerIconSprite }
					stageRenderData={stageRenderData}
					x= { this.props.x - this.props.width + 55 }
					y= { this.props.y - (this.props.height / 2) }
					width = {35}
					height = {35}
					anchor={ {x: 0.5, y: 0.5} } />

				{ meeplesStates }

			</Container>

		);
	}
}

export default PixiGamePlayerRow;