import React from 'react';

class MessageList extends React.Component
{
  scrollToBottom = false;

  constructor(props)
  {
    super(props);

    this.myRef = React.createRef();
  }

  componentDidUpdate()
  {
    if( this.scrollToBottom )
    {
      this.myRef.current.scrollTo( 0, this.myRef.current.scrollHeight );

      this.scrollToBottom = false;
    }
  }

  isScrollAtBottom = () =>
  {
    if( !this.myRef.current )
    {
      return true;
    }

    const scrollTopAtBottomValue = this.myRef.current.scrollHeight - this.myRef.current.clientHeight - 3;

    const isAtScrollBottom = this.myRef.current.scrollTop >= scrollTopAtBottomValue;

    return isAtScrollBottom;
  };

  render()
  {
    if( this.isScrollAtBottom() )
    {
      this.scrollToBottom = true;
    }

    let
    {
      messages,
      containerClassName,
      messageLineClassName
    } = this.props;


    let messageElements = [];

    for( let idx=0; idx < messages.length; idx++ )
    {
        messageElements.push (
          <div
            className={messageLineClassName}
            key={ "message_" + idx }
            >
              { messages[idx].prefix && (<span className="message-line-prefix">{ messages[idx].prefix }</span>) }
              { messages[idx].text }
          </div>
        );
    }

    return (
      <div
          className={containerClassName}
          ref={this.myRef}
          style={{
            overflowY: 'scroll'
          }}
        >
        {messageElements}
      </div>
    );
  }
}

export default MessageList;
