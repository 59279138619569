import React from 'react';
import { Sprite, NineSlicePlane } from '@inlet/react-pixi';

const PixiScaledSprite = ({ x, y, imageSrc, stageRenderData, ...props }) => {

	const stageWidth = stageRenderData.width;
	const stageHeight = stageRenderData.height;
	const renderScale = stageRenderData.scale;
	const stageWidthRef = stageRenderData.refWidth;
	const stageHeightRef = stageRenderData.refHeight;


	const setupProps =
	{
		x: (x / stageWidthRef) * stageWidth,
		y: (y / stageHeightRef) * stageHeight,
	}

	if(props.width)
	{
		setupProps.width = props.width * renderScale;
	}

	if(props.height)
	{
		setupProps.height = props.height * renderScale;
	}

	if(props.isNineSlice)
	{
		setupProps.leftWidth = (props.cornerRadius ?? 10); // * renderScale;
        setupProps.topHeight = (props.cornerRadius ?? 10); // * renderScale;
       	setupProps.rightWidth = (props.cornerRadius ?? 10); // * renderScale;
        setupProps.bottomHeight = (props.cornerRadius ?? 10); // * renderScale;
        setupProps.pivot = {
        	x: (props.width * renderScale) * (props.anchor && props.anchor.x ? props.anchor.x : 0),
        	y: (props.height * renderScale) * (props.anchor && props.anchor.y ? props.anchor.y : 0)
        };

        return (
			<NineSlicePlane 
				image={imageSrc}
				tint={ props.tint ? props.tint : 0xffffff }
				alpha={ props.alpha ? props.alpha : 1 }
				// scale={ (props.scale ? props.scale : 1) * renderScale }
				{... setupProps}
				interactive={ props.onClick ? true : false }
				pointerdown={ (props.onClick ?? null) }
			/>
		);
	}
	else
	{
		return (
			<Sprite
				image={imageSrc}
				tint={ props.tint ? props.tint : 0xffffff }
				alpha={ props.alpha ? props.alpha : 1 }
				scale={ (props.scale ? props.scale : 1) * renderScale }
				{... setupProps}
				anchor={ ( props.anchor ? props.anchor : 0 )}
				interactive={ props.onClick ? true : false }
				pointerdown={ (props.onClick ?? null) }
			/>
		);
	}
}

export default PixiScaledSprite;