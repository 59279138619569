import React from 'react';
import { emit } from '../Socket/game.Emitters';
import MessageList from './MessageList';

import { gameStateManager } from '../../App';

class LobbyChat extends React.Component
{

  state = {
    inputTextValue: "",
    messages: []
  };

  constructor(props)
  {
    super(props);

    this.myRef = React.createRef();

    gameStateManager.addOnRoomChatMessageCallback( (chatTextData) =>
    {
      // {username: 'TomLocal', chatText: 'FARTS'}
      // {chatBotMessage: 'Please don't swear.'}

      let messageData;

      if( chatTextData.chatBotMessage )
      {
        // Message from 'chat bot'
        messageData =
        {
          prefix: "ChatBot: ",
          text: chatTextData.chatBotMessage
        };
      }
      else
      {
        // Message from room player

        messageData =
        {
          prefix: chatTextData.username + ": ",
          text: chatTextData.chatText
        };
      }

      this.setState(state => ({ messages: [...state.messages, messageData] }));
    });
  }

  onTextInputChange = (e) =>
  {
    let currentChatTextValue = e.target.value;

    if( currentChatTextValue.length > 200 )
    {
      // Cap the text input length at 200 characters max
      currentChatTextValue.length = 200;
    }

    this.setState({ inputTextValue : currentChatTextValue });
  };

  handleChatTextSubmit = (e) =>
  {
    // Prevent the browser from reloading the page
    e.preventDefault();

    // Read the form data
    const form = e.target;
    const formData = new FormData(form);
    const submittedChatText = formData.get('chat-input-text') ;

    // console.log( "submittedChatText : ", submittedChatText );

    emit.submitChatText( submittedChatText );

    // Clear current input text
    this.setState({ inputTextValue : "" });
  };

  render()
  {
    const { messages } = this.state;

    return (
      <>
        <div className="column-header" >
          Lobby Chat
        </div>

        <MessageList
            containerClassName="room-chat"
            messageLineClassName="message-list-line"
            messages={messages}
          />

        <form method="post" onSubmit={this.handleChatTextSubmit}>
          <input
            autoComplete="off"
            type="text"
            placeholder="Type here..."
            name="chat-input-text"
            id="lobby_chat_input"
            maxLength="200"
            value={this.state.inputTextValue}
            onChange={this.onTextInputChange}
            />

          <button type="submit" id="lobby_chat_button"> ^ </button>
        </form>
      </>
    );
  }
}

export default LobbyChat;
