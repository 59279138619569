import React from 'react';
import { Container } from '@inlet/react-pixi';
import PixiScaledSprite from "./PixiScaledSprite"
import PixiScaledText from "./PixiScaledText"
import { utils } from '../../utils'

class PixiTurnTimer extends React.Component
{
  state = {

    timerUntil: 0,
    timeRemaining: 0

  };

  setupTimerData()
  {
    const timerEndLocalTimestamp = utils.getLocalTimestampFromUTCTimestamp( this.props.timerData.timerEndUTC );
    const timerExpireTime = new Date( timerEndLocalTimestamp ).getTime();

    this.setState({ timerUntil: timerExpireTime });
    this.setState({ timeRemaining: (timerExpireTime - Date.now()) });
  }

  componentDidUpdate( prevProps )
  {
    if ( prevProps.timerData.timerEndUTC !== this.props.timerData.timerEndUTC )
    {
      this.setupTimerData();
    }
  }

  componentDidMount()
  {
    this.timerID = setInterval(
      () => this.tick(),
      1000
    );

    this.setupTimerData();
  }

  componentWillUnmount()
  {
    clearInterval(this.timerID);
  }

  tick()
  {
    this.setState({ timeRemaining: ( this.state.timerUntil - (new Date()).getTime() ) });
  }

  textStyle = {
    align: "center",
    fontWeight: "bold", // "normal"
    fill: "#111111"
  };

  render() {

    const stageRenderData = this.props.stageRenderData;

    const stageWidthRef = stageRenderData.refWidth;
    const stageHeightRef = stageRenderData.refHeight;

    let timeRemainingToDisplay = Math.round( this.state.timeRemaining / 1000 );
    let timerString = "";

    if( timeRemainingToDisplay < 0 )
    {
      timeRemainingToDisplay = 0;
    }

    const numMinutes = Math.floor( timeRemainingToDisplay / 60.0 ); 
    const numSeconds = timeRemainingToDisplay % 60;

    timerString = (numMinutes < 10 ? "0" : "") + numMinutes;

    timerString += ":";

    timerString += (numSeconds < 10 ? "0" : "") + numSeconds;

    return (

      <Container >
        <PixiScaledSprite
          imageSrc={"./ui/panel.png"}
          isNineSlice={true}
          cornerRadius={15}
          stageRenderData={ stageRenderData }
          x= { stageWidthRef - 345 }
          y= { stageHeightRef - 2 }
          width = {100}
          height = {40}
          anchor={ {x: 1, y: 1} }
           />

        <PixiScaledText
            text={ timerString }
            stageRenderData={ stageRenderData }
            x= { stageWidthRef - 393 }
            y= { stageHeightRef - 22 }
            anchor={ {x: 0.5 , y: 0.5 }}
            style={this.textStyle}
          />

      </Container>


    );
  }
}

export default PixiTurnTimer;