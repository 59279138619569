import React from 'react';
import { Text } from '@inlet/react-pixi';

const PixiScaledText = ({ x, y, text, style, stageRenderData, fontSize = 20, ...props }) => {

	const stageWidth = stageRenderData.width;
	const stageHeight = stageRenderData.height;
	const renderScale = stageRenderData.scale;
	const stageWidthRef = stageRenderData.refWidth;
	const stageHeightRef = stageRenderData.refHeight;

	style.fontSize = fontSize * renderScale;

	return (

		<Text
          text={text}
          x= {(x / stageWidthRef) * stageWidth}
		  y= {(y / stageHeightRef) * stageHeight}
          anchor={ ( props.anchor ? props.anchor : 0 )}
          style={style}
        />

	);
}

export default PixiScaledText;