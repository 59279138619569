import { useEffect, useState } from 'react';

const POPUP_LIMIT = 20;

export const reducer = (state, action) =>
{
  switch (action.type)
  {
    case 'ADD_POPUP':
      return {
        ...state,
        popups: [action.popup, ...state.popups].slice(0, POPUP_LIMIT),
      };

    case 'REMOVE_POPUP':

      if (action.popupId === undefined)
      {
        return {
          ...state,
          popups: [],
        };
      }
      return {
        ...state,
        popups: state.popups.filter((t) => t.id !== action.popupId),
      };
  }
};

const listeners = [];

let memoryState = { popups: [], pausedAt: undefined };

export const dispatch = (action) =>
{
  memoryState = reducer(memoryState, action);

  listeners.forEach((listener) =>
  {
    listener(memoryState);
  });
};

export const useStore = () =>
{
  const [state, setState] = useState(memoryState);

  useEffect(() =>
  {
    listeners.push(setState);

    return () =>
    {
      const index = listeners.indexOf(setState);
      if (index > -1)
      {
        listeners.splice(index, 1);
      }
    };
  }, [state]);

  const mergedPopups = state.popups.map((t) => ({
    
    ...t

  }));

  // console.log("mergedPopups", mergedPopups);

  return {
    ...state,
    popups: mergedPopups,
  };
};