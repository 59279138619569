import { gameStateManager } from '../../App';


export const emit =
{
  toggleIsReadyState: () =>
  {
    gameStateManager.socket.emit('is-ready');
  },

  submitChatText: ( chatText ) =>
  {
    gameStateManager.socket.emit('submit-chat-text', chatText);
  },

  hostSetRoomPrivateState: ( isPrivateState ) =>
  {
    gameStateManager.socket.emit('mark-room-private', isPrivateState);
  },

  hostAddBotToRoom: () =>
  {
    gameStateManager.socket.emit('host-add-bot-to-room');
  },

  hostKickHumanPlayer: ( userIdToKick ) =>
  {
    gameStateManager.socket.emit('host-kick-human-player', userIdToKick);
  },

  hostKickBotPlayer: ( botIndex ) =>
  {
    gameStateManager.socket.emit('host-kick-bot-player', botIndex);
  },

  requestTeamColourChange: ( playerIdx, colourID ) =>
  {
    gameStateManager.socket.emit('set-team-colour', playerIdx, colourID);
  },

  hostToggleRoomStartGame: () =>
  {
    gameStateManager.socket.emit('start-room-game');
  },

  playerTurnPass: item => {
    gameStateManager.socket.emit('player-turn-pass', item);
  },

  // hostChangeRoomOptions: options => {
  //   gameStateManager.socket.emit('host-change-room-options', options);
  // },

  hostChangeMaxPlayers: maxPlayers => {
    gameStateManager.socket.emit('host-change-max-players', maxPlayers);
  },

  hostChangeGameSpeed: gameSpeed => {
    gameStateManager.socket.emit('host-change-game-speed', gameSpeed);
  },

  playerTilePlacement: placementData =>
  {
  	gameStateManager.socket.emit('tile-placed', placementData);
  },

  playerMeeplePlacement: placementData =>
  {
    gameStateManager.socket.emit('meeple-placed', placementData);
  },

  playerSkipMeeplePlacement: () =>
  {
    gameStateManager.socket.emit('skip-meeple');
  },

  submitBugReport: ( userBugContext ) => {
    gameStateManager.socket.emit('submit-bug-report', userBugContext);
  }

  ,closeConnection: () => {
    gameStateManager.socket.close();
  }
};

export const emitDebug = process.env.NODE_ENV !== 'production' ?
{

  hostSkipStartShuffleTrue: () =>
  {
    gameStateManager.socket.emit('host-skip-start-shuffle', true);
  },

  hostSkipStartShuffleFalse: () =>
  {
    gameStateManager.socket.emit('host-skip-start-shuffle', false);
  },

  hostSetTilesNumberLimit: ( tileLimit ) =>
  {
    gameStateManager.socket.emit('host-set-tiles-number-limit', tileLimit)
  }

} : null;





