import React from 'react';
import NavigateSpan from '../Utils/NavigateSpan';

function Footer( props )
{
	return (

		<div className="footer">
			<div className="footer-version-number">
				<NavigateSpan href={"/patch-notes"} textLink={true} newTab={true} >
              		v0.1
            	</NavigateSpan>
        	</div>

			<div className="footer-menu-links">
				<div className="footer-menu-links-container">
					<NavigateSpan href={"/rules"} textLink={true} newTab={true}>
	              		Rules
	            	</NavigateSpan>
	            	<NavigateSpan href={"/about"} textLink={true} newTab={true}>
	              		About
	            	</NavigateSpan>
	            	<NavigateSpan href={"/faq"} textLink={true} newTab={true}>
	              		FAQ
	            	</NavigateSpan>
	            	<NavigateSpan href={"/privacy"} textLink={true} newTab={true}>
	              		Privacy
	            	</NavigateSpan>
	            </div>
			</div>

			<div className="footer-social-links">



			</div>
		</div>

	);
}
export default Footer;