export let socketUrl = process.env.REACT_APP_SOCKET_URL;
export let restUrl = process.env.REACT_APP_REST_URL;

export const unregisteredProfileCookie = "non-reg-profile-token";
export const googleIDTokenCookie = "oauth-id-token";
export const googleRefreshTokenCookie = "oauth-refresh-token";

export const minNumPlayers = 2;
export const maxNumPlayers = 5;

export const maxGameTimerMode = 4;

export const gameTimerModeNames = [ "Super Slow", "Slow", "Normal", "Fast", "Super Fast" ];