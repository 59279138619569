import React from 'react';
import { useNavigate } from 'react-router-dom';
import { gameStateManager } from '../../App';

function NavigateSpan( props )
{
	let navigate = useNavigate();

	const onClickHandler = () =>
	{
		if( props.newTab )
		{
			window.open(props.href,'_blank');
			return;
		}

		// Clear any current game room state data
		gameStateManager.resetStateData();

		// Redirect to Lobby
		navigate( props.href , { replace: true });
	};


	return (

		<span className={"clickable " + (props.textLink ? "clickable-text" : "")} onClick={ onClickHandler }>
			{props.children}
		</span>

	);
}
export default NavigateSpan;