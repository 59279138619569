
export const utils =
{
	getUTCTimestampForSecondsInFuture: (seconds) =>
	{
		const currentTime = new Date();
		const timestamp = currentTime.getTime()		// This should already be in UTC relative 
							// + ( currentTime.getTimezoneOffset() * 60 * 1000 ) 
							+ ( seconds * 1000 );

		return timestamp;
	},

	getLocalTimestampFromUTCTimestamp: (utcTimestamp) =>
	{
		const currentTime = new Date();

	    const timestamp = utcTimestamp - ( currentTime.getTimezoneOffset() * 60 * 1000 );

	    return timestamp;
	},

	getRandomInt: (min, max) =>
	{
		min = Math.ceil(min);
		max = Math.floor(max);
		return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
	},

	coordToHash: ( x, y ) =>
    {
        return (x + 256) + (( y + 256 ) * 512);
    },

    hashToCoord: ( hash ) =>
    {
        let y = Math.floor( hash / 512 );
        let x = hash % 512;
        return { x: x - 256, y: y - 256 };
    },

    hashToCoordString: ( hash ) =>
    {
    	if( hash == null )
    	{
    		return null;
    	}

        let y = Math.floor( hash / 512 );
        let x = hash % 512;
        return "[" + (x - 256) + "," +  (y - 256) + "]";
    },


    coordPlus: ( thisCoord, plusThisCoord ) =>
    {
    	return { x: thisCoord.x + plusThisCoord.x, y: thisCoord.y + plusThisCoord.y };
    },

    coordMinus: ( thisCoord, minusThisCoord ) =>
    {
    	return { x: thisCoord.x - minusThisCoord.x, y: thisCoord.y - minusThisCoord.y };
    },


    localCoordToWorld: ( coord, worldRotation ) =>
    {
    	if( worldRotation === 0 )
    	{
    		return coord;
    	}
    	else if( worldRotation === 1 )
    	{
    		return { x: coord.y, y: coord.x * -1.0 };
    	}
    	else if( worldRotation === 2 )
    	{
    		return { x: coord.x * -1.0, y: coord.y * -1.0 };
    	}
    	else if( worldRotation === 3 )
    	{
    		return { x: coord.y * -1.0, y: coord.x };
    	}

    	console.log("localCoordToWorld() Error: Expected worldRotation to be value between 0 and 3");

    	return coord;
    },



    indexOfAll: (array, searchItem) =>
    {
		let i = array.indexOf(searchItem);
		let indexes = [];

		while (i !== -1)
		{
			indexes.push(i);
			i = array.indexOf(searchItem, ++i);
		}
		return indexes;
	},


	generateRandomHash: (length) =>
	{
	  var result = '';
	  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
	  var charactersLength = characters.length;

	  for (let i = 0; i < length; i++)
	  {
	    result += characters.charAt(Math.floor(Math.random() * charactersLength));
	  }
	  
	  return result;
	},

	sleep: ms =>
	{
	  return new Promise(resolve => setTimeout(resolve, ms))
	},


	/**
     * Shuffle an array of items
     * Uses Fisher-Yates shuffle algorithm
     */
    shuffleArray: ( arrayOfItems ) =>
    {
        // Shuffle the order of arrayOfItems and return a new order
        let j;
        let x;
        let i;

        for (i = arrayOfItems.length - 1; i > 0; i--)
        {
            j = Math.floor(Math.random() * (i + 1));
            x = arrayOfItems[i];
            arrayOfItems[i] = arrayOfItems[j];
            arrayOfItems[j] = x;
        }

        return arrayOfItems;
    }
};
