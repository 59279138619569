import React from 'react';
import { Col } from 'react-bootstrap';
import PageContainerWithLogo from '../Core/PageContainerWithLogo';
import { WaitUntilLoggedIn } from '../Core/LoginManager';
import { restUrl } from '../../env';
import axios from 'axios';
import NavigateSpan from '../Utils/NavigateSpan';

export default class Profile extends React.Component
{
  state = {
    activeSectionIndex: 0,

    userID: null,
    totalGamesPlayed: 0,
    totalGamesWon: 0,
    totalWinningPercentage: 0,
    last100GamesWon: 0,
    last100GamesCompleted: 0,
    last100NumPlayedGames: 0,
    karmaGamesCompleted: 0,
    karmaNumGames: 0,

    last100PlayedGames: null
  };

  sectionNames = ["Overview", "History", "Account"];
  sectionTitles = ["Overview", "History of Last 100 Games", "Account"];

  async componentDidMount()
  {
    await WaitUntilLoggedIn( async () =>
    {
        await this.fetchProfileOverviewData();
    });
  }

  async fetchProfileOverviewData()
  {
    const response = await axios.post(`${restUrl}/profile-overview`, { username: this.props.profileName });

    if( !response || !response.data )
    {
      return;
    }

    this.setState({ 
                    userID: response.data.userID,
                    totalGamesPlayed: response.data.totalGamesPlayed,
                    totalGamesWon: response.data.totalGamesWon,
                    totalWinningPercentage: response.data.totalWinningPercentage,
                    last100GamesWon: response.data.last100GamesWon,
                    last100GamesCompleted: response.data.last100GamesCompleted,
                    last100NumPlayedGames: response.data.last100PlayedGames,
                    karmaGamesCompleted: response.data.karmaGamesCompleted,
                    karmaNumGames: response.data.karmaNumGames
                  });
  }

  async fetchProfileHistoryData()
  {
    const response = await axios.post(`${restUrl}/profile-history`, { username: this.props.profileName });

    if( !response || !response.data )
    {
      return;
    }

    // console.log("fetchProfileHistoryData - response : ", response);

    this.setState({ 
                    last100PlayedGames: response.data.last100PlayedGames
                  });
  }

  onProfileSectionClicked( sectionIndex )
  {
    this.setState({activeSectionIndex: sectionIndex});

    switch( sectionIndex )
    {
      case 1: // History
        if( this.state.last100PlayedGames === null )
        {
          // Call to fetch this profile's game history data
          this.fetchProfileHistoryData();
        }
        break;
      default:
        break;
    }
  }

  getSectionContentsForIndex( index )
  {
    switch( index )
    {
      case 0: // Overview
        return this.getOverviewSectionContents();
      case 1: // History
        return this.getHistorySectionContents();
      default:
        break;

    }
  }

  getOverviewSectionContents()
  {
    let {
          totalGamesPlayed,
          totalWinningPercentage,
          last100GamesWon,
          last100GamesCompleted,
          last100NumPlayedGames,
          karmaGamesCompleted,
          karmaNumGames
        } = this.state;

    return (

      <div className="profile-overview-content">
        <div className="profile-overview-stat"><span>Wins</span><span>{last100GamesWon}/{last100NumPlayedGames}</span></div>
        <div className="profile-overview-stat"><span>Completed Games</span><span>{last100GamesCompleted}/{last100NumPlayedGames}</span></div>
        <div className="profile-overview-stat"><span>Win Percentage</span><span>{totalWinningPercentage}%</span></div>
        <div className="profile-overview-stat"><span>Karma</span><span>{karmaGamesCompleted}/{karmaNumGames}</span></div>
        <div className="profile-overview-stat"><span>Total Games</span><span>{totalGamesPlayed}</span></div>
      </div>

    );
  }

  getHistorySectionContents()
  {
    let {
          userID,
          last100PlayedGames
        } = this.state;

    let gamesHistoryContent = [];;

    if( last100PlayedGames )
    {
      for( let idx=last100PlayedGames.length-1; idx >= 0; idx-- )
      {
        let playedGameData = last100PlayedGames[idx];
        let createdDate = new Date(playedGameData.createdDate);
        let dateString = createdDate.getDate().toString().padStart(2, '0') + "/" + createdDate.getMonth().toString().padStart(2, '0') + "/" + createdDate.getFullYear();
        let timeString = createdDate.getHours().toString().padStart(2, '0') + ":" + createdDate.getMinutes().toString().padStart(2, '0') + ":" + createdDate.getSeconds().toString().padStart(2, '0');
        let rankString = "0/0";
        let gameDurationSecondsTotal = playedGameData.gameDuration;
        let gameDurationSeconds = gameDurationSecondsTotal % 60;
        let gameDurationMinsTotal = Math.floor( gameDurationSecondsTotal / 60.0 );
        let gameDurationMins = gameDurationMinsTotal % 60;
        let gameDurationHours = Math.floor( gameDurationMinsTotal / 60.0 );
        let durationString = (gameDurationHours > 0 ? gameDurationHours.toString().padStart(2, '0') + ":" : "") + gameDurationMins.toString().padStart(2, '0') + ":" + gameDurationSeconds.toString().padStart(2, '0');
        let finishedString = playedGameData.gameFinished;
        let otherPlayersContent = [];

        for( let pIdx=0; pIdx < playedGameData.players.length; pIdx++ )
        {
          let gamePlayerData = playedGameData.players[pIdx];

          if( gamePlayerData.userID === userID )
          {
            // Found this user's entry
            rankString = playedGameData.ranks[ pIdx ] + "/" + playedGameData.players.length;
          }
          else
          {
            if( gamePlayerData.userID )
            {
              // Human player
              otherPlayersContent.push(
                <NavigateSpan href={"/profile/" + gamePlayerData.username} newTab={true} textLink={true} >
                  {gamePlayerData.username}
                </NavigateSpan>
                );
            }
            else
            {
              // Bot
              otherPlayersContent.push(<span>Bot</span>);
            }
          }
        }

        gamesHistoryContent.push(
          <tr>
            <td>{ dateString + ", " + timeString }</td>
            <td>{ rankString }</td>
            <td>{ durationString  }</td>
            <td>{ finishedString ? "True" : "False" }</td>
            <td className="opponent-names-list" >{ otherPlayersContent }</td>
            {/*<td>Info</td>*/}
          </tr>
        );
      }
    }

    return (

      <div className="profile-history-content">

        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Rank</th>
              <th>Duration</th>
              <th>Finished</th>
              <th>Opponents</th>
              {/*<th>Info</th>*/}
            </tr>
          </thead>
          <tbody>
            {gamesHistoryContent}
          </tbody>
        </table>
      </div>

    );
  }

  render()
  {
    let {
          activeSectionIndex
        } = this.state;

    let sectionSpans = [];

    for(let idx=0; idx < this.sectionNames.length; idx++)
    {
      sectionSpans.push(  
                          <span className={ idx === activeSectionIndex ? "selected" : "" }
                                onClick={() => { this.onProfileSectionClicked(idx) }} >

                            {this.sectionNames[idx]}

                          </span>
                        );
    }

    return (
      <>
        <PageContainerWithLogo>
            <Col xs="3">
              {/*<div className="panel">
                
              </div>*/}
              <div className="component-menu-panel">
                { sectionSpans }
              </div>
            </Col>
            <Col>
              <div className="profile_page_main_section">

                <div className="profile-page-header">
                  <img className="profile-avatar" src={process.env.PUBLIC_URL + '/avatars/basic_1.png'} alt="Avatar icon" />
                  <span>{this.props.profileName}</span>
                </div>

                <div className="profile-page-section-title">
                  {this.sectionTitles[activeSectionIndex]}
                </div>

                {this.getSectionContentsForIndex(activeSectionIndex)}
              </div>

            </Col>
            {/*<Col xs="3">
              <div className="panel">
                
              </div>
            </Col>*/}
        </PageContainerWithLogo>

      </>
    );
  }
}



