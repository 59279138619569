import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default class ErrorHandler extends React.Component {
  /**
   * Render Error modal
   * Received props -> redirectUrl  string       - URL to redirect to, after processing error
   *                -> error        Object       - Topic and Message for the error
   *                -> resetError   function     - Reset Error state on parent component
   */
  state =
  {
    redirect: false
  };

  static propTypes =
  {
    redirectUrl: PropTypes.string.isRequired,
    error: PropTypes.object.isRequired,
    resetError: PropTypes.func.isRequired
  };

  handleError = () =>
  {
    const { resetError } = this.props;
    this.setState({ redirect: true });
    resetError();
  }

  render() {
    const { redirectUrl, error } = this.props;
    const { redirect } = this.state;

    if (redirect)
    {
      return (
        <div>
          <Navigate to={`${redirectUrl}`} />
        </div>
      );
    }

    return (
      
      <Modal show={true} onHide={this.handleError}>
        <Modal.Header closeButton>
          <Modal.Title>{error.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{error.content}</Modal.Body>
        <Modal.Footer>
         
          <Button variant="primary" onClick={this.handleError}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
