import React from 'react';

export default class SliderOption extends React.Component
{
  constructor(props)
  {
    super(props);

    this.state =
    {
      sliderValue: props.value
    }
  }

  handleMouseEvent = (event) =>
  {
    if (event.type === "mousedown")
    {
      // console.log("SliderOption - mouse down");
    }
    else
    {
      // mouse up
      this.props.onValueChange( this.state.sliderValue );
    }
  }

  onSliderMoving = ( e ) =>
  {
    // console.log("onSliderMoving : ", e.target.value);

    this.setState({ sliderValue: Number( e.target.value ) });
  }

  render() {

    return (
        
      <div>
        <span style={{paddingRight: '10px'}}>{this.props.title}</span>

        <input
          type="range"
          min={this.props.min}
          max={this.props.max}
          value={this.state.sliderValue} //{this.props.value}
          onMouseDown={ this.handleMouseEvent } onMouseUp={ this.handleMouseEvent }
          onChange={ this.onSliderMoving }
        />

        <span style={{paddingLeft: '10px'}}>{this.state.sliderValue}</span>

      </div>

    );
  }
}
