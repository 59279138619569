import React from 'react';

function Button( props )
{

	let className = "button";

	if( props.active )
	{
		className = "button-active";
	}
	else if( props.disabled )
	{
		className = "button-disabled";
	}

	return (

		<span className={className} onClick={ () => {
			
			props.onClick();

		} }>
			{props.children}
		</span>

	);
}
export default Button;