import React from 'react';
import PropTypes from 'prop-types';
import { emit } from '../Socket/game.Emitters';
import Button from '../Utils/Button';
import { loggedInUsername, numUsernameChangesLeft } from '../Core/LoginManager'
import EditUsernamePopup from '../Popups/EditUsernamePopup';
import ConfirmationPopup from '../Popups/ConfirmationPopup';
import { popup } from '../Utils/Popup';

class JoinedPlayers extends React.Component
{
  state = {
    showEditUsernameOverlay: false,
  };

  onUsernameChangeClosed = ( newUsername ) =>
  {
    this.setState( { showEditUsernameOverlay: false } );
  };

  onPlayerColourChanged = ( playerIdx, colourID ) =>
  {
    emit.requestTeamColourChange( playerIdx, colourID );
  };

  render()
  {
    const { playersInRoom, clientId, maxPlayers, isRoomHost } = this.props;

    // console.log( "[DEBUG] PLAYERS JOINED ARE: ", playersInRoom );

    const playersList = [];
    let botIndex = 0;

    // Construct the player entry rows
    for( let idx=0; idx < playersInRoom.length; idx++ )
    {
      let playerInfo = playersInRoom[idx];

      const isBot = playerInfo.isBot;
      const currentBotIndex = botIndex;
      const isClientEntry = !isBot && playerInfo.socketID === clientId;
      const keyValue = (isBot ? "bot" + idx : playerInfo.id) + "_" + idx;
      const isReady = isBot || playerInfo.isReady;

      playersList.push(
          <div  className={"room-details-player-block " + (isClientEntry ? "room-details-player-block-client-entry" : "") }
                key={keyValue}
                onClick={ () =>
                {
                  if( isClientEntry )
                  {
                    this.setState( { showEditUsernameOverlay: true } );
                  }
                  else if( isRoomHost )
                  {
                    popup( <ConfirmationPopup
                              title={"Kick " + ( isBot ? "Bot" : "Player" )}
                              body={"Want to kick out " + ( isBot ? "bot" : "" ) + " player " + playerInfo.username + "?"}
                              buttonText={"Kick"}
                              onConfirm={()=>
                              {
                                if( isBot )
                                {
                                  emit.hostKickBotPlayer( currentBotIndex );
                                }
                                else
                                {
                                  emit.hostKickHumanPlayer( playerInfo.userID );
                                }
                              }}
                            /> );
                  }
                  else if( !isBot )
                  {
                    console.log("Go to profile page for " + playerInfo.username );
                  }
                }}
          >

            <div className="room-details-player-block-left">
              <div className="room-details-player-row">

                <img className="room-details-player-icon" src={process.env.PUBLIC_URL + '/avatars/meeple-' + playerInfo.colourID + '.png'} alt="Meeple Icon" />

                { playerInfo.username }

                { isBot && <img className="room-details-player-icon" src={process.env.PUBLIC_URL + '/ui/icon-bot.svg' } alt="Bot icon" /> }
                
                { isClientEntry && <img className="room-details-edit-username-icon"
                                        src={process.env.PUBLIC_URL + '/ui/icon-pencil.svg'}
                                        alt="Edit Username icon"
                                        />}

              </div>

              {
                !isBot &&
                <div className="room-details-player-row">
                  { "Karma: " + playerInfo.karmaGamesCompleted + "/" + playerInfo.karmaGamesTotal }
                  {/*<img className="room-details-player-icon" src={process.env.PUBLIC_URL + '/ui/icon-info.svg'} alt="Info icon" />*/}
                </div>
              }
              

              { (isClientEntry || (isRoomHost && isBot)) && 
                <select
                  defaultValue={playerInfo.colourID}
                  onChange={ (e) => { this.onPlayerColourChanged( idx, e.target.value ) } }
                  onClick={ (e) => { e.stopPropagation(); } }
                >
                  <option value="blue">Blue</option>
                  <option value="red">Red</option>
                  <option value="green">Green</option>
                  <option value="yellow">Yellow</option>
                  <option value="white">White</option>
                  <option value="purple">Purple</option>
                  <option value="black">Black</option>
                  <option value="grey">Grey</option>
                </select>
              }
              
            </div>

            <div className="room-details-player-block-right">
              {
                isReady &&
                <>
                  <div>Ready</div>
                  <img className="room-details-player-ready-tick" src={process.env.PUBLIC_URL + '/ui/icon-check.svg'} alt="Checked Icon" />
                </>
              }
              {
                !isReady &&
                <>
                  <div>Not Ready</div>
                  <img className="room-details-player-ready-tick" src={process.env.PUBLIC_URL + '/ui/icon-x.svg'} alt="Checked Icon" />
                </>
              }
            </div>
          </div>
        );
  
      if( isBot )
      {
        botIndex++;
      }
    }

    // Construct remaining empty player slots..
    for(var idx = playersInRoom.length; idx < maxPlayers; idx++)
    {
      if(isRoomHost)
      {
        playersList.push(
          <div key={"emptyPlayerSlot_" + idx} className="room-details-player-block-bot">
            <Button
              style={{ fontSize: 10, margin: "4px auto 4px auto", width: '50%' }}
              onClick={() =>
              {
                emit.hostAddBotToRoom();
                // console.log("Add Bot");
              }}
            >
              Add Bot
            </Button>
          </div>
        );
      }
      else
      {
        playersList.push(
          <div className="room-details-player-block" key={"emptyPlayerSlot_" + idx}>
              
          </div>
        );
      }
      
    }

    return (
      <>
        <div className="column-header">
          Players
        </div>

        <div className="room-details-players-list">

          { playersList }

        </div>


        {
          this.state.showEditUsernameOverlay &&
          <EditUsernamePopup close={this.onUsernameChangeClosed} value={loggedInUsername} changeUsername={this.onUsernameChangeClosed} numUsernameChangesLeft={numUsernameChangesLeft}  />
        }
      </>
    );
  }
}

export default JoinedPlayers;
