import React from 'react';
import EditUsernamePopup from '../Popups/EditUsernamePopup';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Foco from 'react-foco';
import { GoogleLogin, GoogleLogout } from 'react-google-login';
import NavigateSpan from '../Utils/NavigateSpan';
import Cookies from 'js-cookie';
import { googleRefreshTokenCookie } from '../../env';
import { RegisterOnUsernameChangeCallback, UnregisterOnUsernameChangeCallback, AttemptUserLoginFlow, LoginWithGoogle, LogoutGoogle, loggedInUsername, loggedInTokenData, loggedInKarmaScore, loggedInKarmaTotal, numUsernameChangesLeft } from '../Core/LoginManager'

export default class UserProfilePanel extends React.Component
{
  state = {
    loggedIn: false,
    showDropdown: false,
    showEditUsernameOverlay: false
  };

  async componentDidMount()
  {
    RegisterOnUsernameChangeCallback( this.onUsernameChanged );

    if (this.props.loggedIn)
    {
      return;
    }

    await this.attemptLoginFlow();
  }

  async componentWillUnmount()
  {
    UnregisterOnUsernameChangeCallback( this.onUsernameChanged );
  }

  async attemptLoginFlow()
  {
    await AttemptUserLoginFlow( ( loginResponseData ) =>
    {
      if( loginResponseData.success )
      {
        this.setState( { loggedIn: true } );
      }
      this.forceUpdate();
    });
  }

  onUsernameChanged = ( newUsername ) =>
  {
    this.forceUpdate();
  }

  onLoginFail = ( res ) =>
  {
    console.log("[Login Failed] res : ", res);
  }

  onLogoutFail = ( res ) =>
  {
    console.log("[Logout Failed] res : ", res);
  }

  onUsernameChangeClosed = ( newUsername ) =>
  {
    this.setState( { showEditUsernameOverlay: false } );
  }

  onGoogleLogin = async ( googleData ) =>
  {
    LoginWithGoogle( googleData, ( loginStatus ) =>
    {
      if( loginStatus )
      {
       this.forceUpdate();
      }
    });
  }

  onGoogleLogout = async () =>
  {
    LogoutGoogle();

    this.attemptLoginFlow();
  }

  render()
  {
    let hasRefreshToken = false;

    if( Cookies.get( googleRefreshTokenCookie ) )
    {
      hasRefreshToken = true;
    }

    const username = loggedInUsername ? loggedInUsername : "Loading...";
    const karmaScore = loggedInKarmaScore;
    const karmaTotal = loggedInKarmaTotal;
    const isGoogleAssociated = loggedInTokenData ? loggedInTokenData.isGoogleAssociated : false;

    const { showDropdown } = this.state;

    return (
      <>
        <div className="top-bar-profile-panel-container">

          <Foco
            onClickOutside={() => { this.setState( { showDropdown: false } ); } }
          >
          
            <div className="top-bar-profile-panel-container-inner">

              <div className="top-bar-profile-panel" onClick={ () =>
                      {
                        this.setState( { showDropdown: !this.state.showDropdown } );
                      } }>
                <img className="top-bar-profile-avatar" src={process.env.PUBLIC_URL + '/avatars/basic_1.png'} alt="Avatar icon" />
                <div className="top-bar-profile-username-section">
                  <div><b>{username}</b></div>
                  <div>Karma: {karmaScore}/{karmaTotal}</div>
                </div>
                
                <span className="dropdown-arrow"><img  src={process.env.PUBLIC_URL + '/ui/icon-down-arrow.png'} alt="Dropdown arrow" /></span> 
              </div>



              <div className="dropdown-container">
                { showDropdown && (
                  <div className="component-dropdown-panel show">



                    {/* View Profile */}

                    <NavigateSpan href={"/profile/" + username} newTab={true} onClick={ () => { this.setState( { showDropdown: false } ); }} >View Profile</NavigateSpan>




                    {/* Edit Username */}

                    <span onClick={ () => { this.setState( { showEditUsernameOverlay: true, showDropdown: false } ); } } >Edit Username</span>





                    {/* Google Social Login */}

                    <>
                      {!isGoogleAssociated && (

                        <GoogleLogin
                                  clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                                  buttonText="Log in with Google?"
                                  onSuccess={this.onGoogleLogin}
                                  onFailure={this.onLoginFail}
                                  cookiePolicy={'single_host_origin'}
                                  responseType="code"

                                  render={renderProps => (
                                    <span onClick={ (a) => {renderProps.onClick(a); this.setState( { showDropdown: false } ); }} disabled={renderProps.disabled}>
                                      Login
                                    </span>
                                  )}

                                  prompt= { hasRefreshToken ? "" : "consent" }  // ensures the user is prompted when we don't have a refresh token, as this will regenerate one.
                              />

                      )}
                      
                      {isGoogleAssociated && (

                            <GoogleLogout
                              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                              buttonText="Logout"
                              onLogoutSuccess={this.onGoogleLogout}
                              onFailure={this.onLogoutFail}

                              render={renderProps => (
                                  <span onClick={ (a) => {renderProps.onClick(a); this.setState( { showDropdown: false } ); }} disabled={renderProps.disabled}>
                                    Log Out
                                  </span>
                            )}
                        />
                      )}
                    </>


                  </div>
                )
              }
              </div>

            </div>

          </Foco>
        </div>

        {
          this.state.showEditUsernameOverlay &&
          <EditUsernamePopup close={this.onUsernameChangeClosed} value={loggedInUsername} changeUsername={this.onUsernameChangeClosed} numUsernameChangesLeft={numUsernameChangesLeft}  />
        }
      </>

      
    );
  }
};
