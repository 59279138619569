import React from 'react';
import Button from './Button';

export default class ToggleOption extends React.Component
{
  render() {

    return (
        
      <div>
        <span style={{paddingRight: '10px'}}>{this.props.title}</span>

        <Button variant="light" onClick={ () =>{ this.props.onTrueState(); }} active={ this.props.currentState } >
          True
        </Button>
        <Button variant="light" onClick={ () =>{ this.props.onFalseState(); }} active={ !this.props.currentState } >
          False
        </Button>

      </div>

    );
  }
}
