import React, { useReducer } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { useLocation } from "react-router-dom";

import { AttemptUserLoginFlow, loggedInTokenData } from '../Core/LoginManager'
import { AttemptAdminPortalLoginFlow, isAdminUser } from '../Core/AdminLoginManager'
import AdminPortal from '../Admin/AdminPortal';

export default function AdminRouter()
{
  const [, forceUpdate] = useReducer(x => x + 1, 0);

  if(!loggedInTokenData)
  {
    // User hasn't logged in yet; must've opened up a fresh browser tab with this room URL
    // Need to log them in first

    AttemptUserLoginFlow(( loginResponseData ) =>
    {
      console.log("AdminRouter :: logging in user...", loginResponseData );

      forceUpdate();
    });
  }
  else if( isAdminUser === undefined )
  {
    AttemptAdminPortalLoginFlow(( loginResponseData ) =>
    {
      console.log("AdminRouter :: logging in ADMIN user...", loginResponseData );

      forceUpdate();
    });
  }
  else
  {
    if( isAdminUser )
    {
      return ( <AdminPortal /> );
    }
    else
    {
      return ( <div>Access Denied</div> );
    }
  }

  return ( <div>Attempting to access admin portal...</div> );
}
