import React from 'react';
import { Stage, Container } from '@inlet/react-pixi';
import { settings, SCALE_MODES } from 'pixi.js';
import PixiTurnTimer from "./PixiTurnTimer";
import PixiScaledSprite from "./PixiScaledSprite"
import PixiScaledText from "./PixiScaledText"
import PixiBoard from "./PixiBoard"
import PixiGamePlayersInfoPanel from "./PixiGamePlayersInfoPanel"
import PixiEndGameOverlay from "./PixiEndGameOverlay"
import { emit } from '../Socket/game.Emitters';


import { gameStateManager } from '../../App';
import { GAME_STATE } from '../Core/GameStateManager';


class PixiGame extends React.Component
{
	state = {

		playerData: null,
		boardTiles: [],
		boardMeeples: [],

	    currentTileSprite: '',
	    validTilePlacements: null,
	    timerData : null,

	    viewEndGameOverlay: null,
	    
	    viewFieldStates: false,

	    showMockupPreview: false
	};


	textStyle = {
	  align: "center",
	  fontWeight: "bold",	// "normal"
	  fill: "#111111"
	};


	onGameStateChangedCallbackHandle = null;


	constructor(props)
	{
		super(props);
		settings.SCALE_MODE = SCALE_MODES.NEAREST

		this.pixiBoardRef = React.createRef();
	}

	componentDidMount()
	{
		// Register for key down event listening
		window.addEventListener('keydown', this.handleKeyDown);

		this.onGameStateChangedCallbackHandle = (gameStateData) =>
		{
		  console.log("PIXI GAME -  New Game State ", gameStateData);

		  this.setState( { gameStateData: gameStateData } );
		};

		gameStateManager.onGameStateChangedRegister( this.onGameStateChangedCallbackHandle );
	}

	componentWillUnmount()
	{
		// Clean up the keydown event listening
		window.removeEventListener('keydown', this.handleKeyDown);

		gameStateManager.onGameStateChangedUnregister( this.onGameStateChangedCallbackHandle );

		this.onGameStateChangedCallbackHandle = null;
	}


	handleKeyDown = (event) =>
	{
		// G : 71
		// M : 77

		if ( process.env.NODE_ENV === 'development' )
		{
			if( event.keyCode === 77 )	// M - Mockup preview
			{
				this.setState( { showMockupPreview: !this.state.showMockupPreview } );
			}
			else if( event.keyCode === 71 )	// G - Game over screen
			{
				if( this.state.viewEndGameOverlay == null )
				{
					this.setState( { viewEndGameOverlay: true } );
				}
				else
				{
					this.setState( { viewEndGameOverlay: !this.state.viewEndGameOverlay } );
				}
			}
		}
	};

	onMeeplePlacementSkipClick = () =>
    {
    	// Tell server that player has skipped their Meeple placement stage
    	emit.playerSkipMeeplePlacement();

    	this.pixiBoardRef.current.clearMeepleTurnData();
    }

    toggleFieldStatusView = () =>
    {
    	this.setState( { viewFieldStates: !this.state.viewFieldStates } );
    }


	pixiStageOptions = {
		backgroundColor: 0xe92162,
	}

	render()
	{
		const
		{
			clientId,
			stageRenderData
		} = this.props;

		const
		{
			showMockupPreview,
			viewFieldStates,

			gameStateData

		} = this.state;

		const stageWidth = stageRenderData.width;
		const stageHeight = stageRenderData.height;

		// Grab out the bits we need from the gameStateData object
		const boardTiles = gameStateData ? gameStateData.boardTiles : null;
		const boardMeeples = gameStateData ? gameStateData.boardMeeples : null;
		const boardFields = gameStateData ? gameStateData.boardFields : null;
		const playerData = gameStateData ? gameStateData.playerData : null;
		const validTilePlacements = gameStateData ? gameStateData.validTilePlacements : null;
		const validMeeplePlacements = gameStateData ? gameStateData.validMeeplePlacements : null;
		const currentTileSprite = gameStateData ? gameStateData.currentTileSprite : null;
		const numTilesRemaining = gameStateData ? gameStateData.numTilesRemaining : null;
		const timerData = gameStateData ? gameStateData.timerData : null;
		const viewEndGameOverlay = this.state.viewEndGameOverlay != null ? this.state.viewEndGameOverlay : gameStateData && gameStateData.gameState === GAME_STATE.GAME_OVER;


		// console.log( "PixiGame Render. boardTiles", boardTiles, "socketRoomBoardTiles", gameStateManager.socketRoomBoardTiles );
		// console.log("PIXI Current Tile Sprite : " + currentTileSprite + ", renderScale : " + renderScale );
		// console.log(validTilePlacements);

		// Get player team colours
		let playerTeamColour = "";
		let teamColours = [];
		
		if( playerData )
		{
			for( let idx=0; idx < playerData.playersJoined.length; idx++ )
			{
				if( playerData.playersJoined[idx].socketID === clientId )
				{
					playerTeamColour = playerData.playersJoined[idx].colourHex;
				}

				teamColours.push( playerData.playersJoined[idx].colourHex );
			}
		}



		let uiMockupReference = [];

		if( process.env.NODE_ENV === 'development' )
		{
			if( showMockupPreview )
			{
				uiMockupReference.push (
					<PixiScaledSprite
						key={"0"}
						imageSrc={"./ui_mockup_ref.png"}
						stageRenderData={stageRenderData}
						x= { 0 }
						y= { 0 }
						scale={ 0.5 }
						anchor={ {x: 0, y: 0} }
				 	   />
		 	   	);
			}
		}


		let endGameOverlay = [];

		if ( viewEndGameOverlay && playerData )
		{
			endGameOverlay.push (
				<PixiEndGameOverlay
					key={"0"}
			    	stageRenderData={stageRenderData}
			    	playerData={playerData}
			    	clientId={clientId}
			    	/>
	 	   	);
		}




		let currentTurnStateContent = [];

		if( currentTileSprite )
		{
			currentTurnStateContent.push(

			    <PixiScaledSprite
			    	key={"current_tile"}
					imageSrc={"./tiles/"  + currentTileSprite + ".png"}
					stageRenderData={stageRenderData}
					x= { stageRenderData.refWidth - 345 }
					y= { stageRenderData.refHeight - 52 }
					width = {150}
					height = {150}
					anchor={ {x: 1, y: 1} }
		 	   	/>

			);
		}

		if( numTilesRemaining !== undefined )
		{
			// Show num tiles remaining.

			currentTurnStateContent.push(

			    <PixiScaledText
			    	key={"tiles_remaining"}
					text={ numTilesRemaining + "" }
					stageRenderData={ stageRenderData }
					x= { stageRenderData.refWidth - 350 }
					y= { stageRenderData.refHeight - 220 }
					anchor={ {x: 1 , y: 1 }}
					style={this.textStyle}
				/>

			);

			currentTurnStateContent.push(

			    <PixiScaledSprite
			    	key={"tile_stack_icon"}
					imageSrc={"./ui/tile_stack_icon.png" }
					stageRenderData={stageRenderData}
					x= { stageRenderData.refWidth - 385 }
					y= { stageRenderData.refHeight - 210 }
					width = {45}
					height = {45}
					anchor={ {x: 1, y: 1} }
		 	   	/>

			);
		}

		return (

			<Stage
				width={stageWidth} height={stageHeight} options={this.pixiStageOptions}
				style={{
					touchAction: "none",
					position: "absolute",
					display: "block",
					left: "50%",
					top: stageRenderData.stageStyleTop + "px",
					transform: "translate(-50%, 0%)"
				}} >

				<PixiBoard
					ref={this.pixiBoardRef}
					boardTiles={boardTiles}
					boardMeeples={boardMeeples}
					currentTileSprite = {currentTileSprite}
					stageRenderData={stageRenderData}
					playerData={playerData}
					playerTeamColour={playerTeamColour}
					teamColours={teamColours}
					validTilePlacements={validTilePlacements}
					validMeeplePlacements={validMeeplePlacements}
					interactive={!viewEndGameOverlay}
					fieldStateData={ viewFieldStates ? boardFields : null }
				/>

			    { currentTurnStateContent }

			    { playerData && (

			    	<PixiGamePlayersInfoPanel
				    	stageRenderData={stageRenderData}
				    	playerData={playerData}
				    	clientId={clientId} />

			    )}
			    
			    { timerData && (

			    	<PixiTurnTimer stageRenderData={stageRenderData} timerData={timerData} />

			    )}

			    { timerData && (

			    	<Container>
						<PixiScaledSprite
							imageSrc={"./ui/panel.png"}
							isNineSlice={true}
							cornerRadius={15}
							stageRenderData={ stageRenderData }
							x= { stageRenderData.refWidth - 455 }
							y= { stageRenderData.refHeight - 2 }
							width = {400}
							height = {40}
							anchor={ {x: 1, y: 1} }
						/>

						<PixiScaledText
							text={ timerData.context }
							stageRenderData={ stageRenderData }
							x= { stageRenderData.refWidth - 655 }
							y= { stageRenderData.refHeight - 22 }
							anchor={ {x: 0.5 , y: 0.5 }}
							style={this.textStyle}
						/>

					</Container>

		    	)}


				{ /* Chcek to show a 'Skip Meeple Placement' button. */ }
		    	{ validMeeplePlacements && (

		    		<Container>
						<PixiScaledSprite
							imageSrc={"./ui/panel.png"}
							isNineSlice={true}
							cornerRadius={15}
							stageRenderData={ stageRenderData }
							x= { 200 }
							y= { 2 }
							width = {400}
							height = {40}
							anchor={ { x: 0.5, y: 0 } }
							onClick={ ()=> { this.onMeeplePlacementSkipClick(); } }
						/>

						<PixiScaledText
							text={ "Skip Meeple Placement?" }
							stageRenderData={ stageRenderData }
							x= { 200 }
							y= { 22 }
							anchor={ {x: 0.5 , y: 0.5 }}
							style={this.textStyle}
						/>

					</Container>

		    	)}

		    	{ /* Check to show a num spectators count */ }
		    	{ playerData && playerData.numSpectators > 0 && (

		    		<Container>
		    			<PixiScaledSprite
							imageSrc={"./ui/spectator-icon.png"}
							stageRenderData={ stageRenderData }
							x= { stageRenderData.refWidth - 400 }
							y= { 22 }
							width = {50}
							height = {50}
							anchor={ { x: 0.5, y: 0.5 } }
						/>

						<PixiScaledText
							text={ playerData ? playerData.numSpectators : "0" }
							stageRenderData={ stageRenderData }
							x= { stageRenderData.refWidth - 370 }
							y= { 22 }
							anchor={ {x: 0 , y: 0.5 }}
							style={this.textStyle}
						/>

					</Container>

		    	)}


		    	
		    	{ boardFields && (

		    		<Container>
						<PixiScaledSprite
							imageSrc={"./ui/fields_button.png"}
							isNineSlice={true}
							cornerRadius={15}
							stageRenderData={ stageRenderData }
							x= { 2 }
							y= { stageRenderData.refHeight - 2 }
							width = {70}
							height = {70}
							anchor={ { x: 0, y: 1 } }
							onClick={ ()=> { this.toggleFieldStatusView(); } }
						/>
					</Container>
		    	)}

			    { showMockupPreview && uiMockupReference }

			    { endGameOverlay }

			</Stage>
		);
	}
}


export default PixiGame;