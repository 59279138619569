import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

import AdminRouter from './components/Routers/AdminRouter';
import ProfileRouter from './components/Routers/ProfileRouter';
import RoomRouter from './components/Routers/RoomRouter';

import GameStateManager from './components/Core/GameStateManager';

export const gameStateManager = new GameStateManager();

export default class App extends React.Component {
  
  render() 
  {
    return (

      <>
        <div><Toaster/></div>

        <BrowserRouter>
    
          <Routes>
            
            <Route path="/" element={<RoomRouter gameStateManager={gameStateManager} />} />

            <Route path="/admin" element={<AdminRouter />} />

            <Route path="/profile/*" element={<ProfileRouter />} />

          </Routes>

        </BrowserRouter>

      </>
    );
  }
}
