import React from 'react';
import { Col, Row } from 'react-bootstrap';
import BugReports from './AdminPortal.BugReports';
import PageContainerWithLogo from '../Core/PageContainerWithLogo';

export default class AdminPortal extends React.Component
{
  render()
  {
    return (

      <PageContainerWithLogo>
        <Row>
          <Col xs="3">
            <div className="panel">
              
            </div>
          </Col>
          <Col>
            <div className="panel">

              <h2>Admin Portal</h2>

              <BugReports />

            </div>

          </Col>
          <Col xs="3">
            <div className="panel">
              
            </div>
          </Col>
        </Row>
      </PageContainerWithLogo>

    );
  }
}


