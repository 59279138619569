import React from 'react';
import Button from '../Utils/Button';
import { Row, Col } from 'react-bootstrap';
import { ChangeUsername } from '../Core/LoginManager'
import Modal from 'react-bootstrap/Modal';

class EditUsernamePopup extends React.Component
{
  state =
  { 
    error: null
  };

  constructor(props) {
    super(props);

    this.state = {value: props.value };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.changeUsername = props.changeUsername;
  }

  handleChange(event)
  {
    this.setState({value: event.target.value});
  }

  async handleSubmit()
  {
    let newUsername = this.state.value;
    let response = await ChangeUsername( newUsername );

    // console.log( "response", response );

    if( response.success )
    {
      this.changeUsername( this.state.value );
    }
    else
    {
      this.setState( { error: response.error } );
    }
  }

  render()
  {
    const numUsernameChangesRemaining = this.props.numUsernameChangesLeft;

    return (

      <Modal show={true} onHide={this.props.close} centered >
        <Modal.Header closeButton>
          <Modal.Title>Change Username</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={this.handleSubmit}>
            <label>
              Change username to:
              <input type="text" value={this.state.value} onChange={this.handleChange} />
            </label>
          </form>

          <div>You have {numUsernameChangesRemaining} username changes remaining.</div>

          <div className="warning-text">{this.state.error}</div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.close} style={{display: 'block', marginLeft: 'auto', marginRight: 'auto' }} >
            Cancel
          </Button>

          <Button onClick={this.handleSubmit} style={{display: 'block', marginLeft: 'auto', marginRight: 'auto' }} >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default EditUsernamePopup;
