import React from 'react';
import PropTypes from 'prop-types';
import LobbyChat from '../Core/LobbyChat';
import JoinedPlayers from '../Core/JoinedPlayers';
import RoomDetails from './RoomDetails';
import { gameStateManager } from '../../App';
import PageContainerWithLogo from '../Core/PageContainerWithLogo';

export default class RoomSetup extends React.Component
{

  static contextTypes = { theme: PropTypes.object };

  state = {
    gameStateData: null,
  };

  onGameStateChangedCallbackHandle = null;

  componentDidMount()
  {
    this.onGameStateChangedCallbackHandle = (gameStateData) =>
    {
      this.setState( { gameStateData: gameStateData } );
    };

    gameStateManager.onGameStateChangedRegister( this.onGameStateChangedCallbackHandle );
  }

  componentWillUnmount()
  {
    gameStateManager.onGameStateChangedUnregister( this.onGameStateChangedCallbackHandle );

    this.onGameStateChangedCallbackHandle = null;
  }

  render()
  {
    const {
      gameStateData
    } = this.state;

    const clientSocketID = gameStateManager.socketClientID;
    const playersInRoom = gameStateData && gameStateData.playerData ? gameStateData.playerData.playersJoined : [];
    const isRoomHost = playersInRoom && playersInRoom.length > 0 && clientSocketID === playersInRoom[0].socketID;

    let thisPlayerData = null;
    for( let idx=0; idx < playersInRoom.length; idx++ )
    {
      if( clientSocketID === playersInRoom[ idx ].socketID )
      {
        thisPlayerData = playersInRoom[ idx ];
        break;
      }
    }
    const thisPlayerMarkedAsReady = thisPlayerData != null ? thisPlayerData.isReady : false;
    const areAllPlayersReady = playersInRoom && playersInRoom.every(player => player.isReady === true);

    return (
        <PageContainerWithLogo>
          <div className="room-setup-container">
            <div className="column-left">
              <JoinedPlayers
                playersInRoom={playersInRoom}
                clientId={clientSocketID}
                isRoomHost={isRoomHost}
                maxPlayers={ gameStateManager.socketRoomOptions.maxPlayersLimit }
              />
            </div>
            <div className="column-mid">
              <RoomDetails
                isRoomHost={isRoomHost}
                areAllPlayersReady={areAllPlayersReady}
                options={gameStateManager.socketRoomOptions}
                isPlayerReady={thisPlayerMarkedAsReady}
              />
            </div>
            <div className="column-right">
              <LobbyChat />
            </div>
          </div>
        </PageContainerWithLogo>
    );
  }
}
