import React from 'react';
import Viewport from "./Viewport";
import { Sprite } from '@inlet/react-pixi';
import { Filter } from 'pixi.js';
import { emit } from '../Socket/game.Emitters';
import { utils } from '../../utils'


const BoardTile = ({ x, y, imageSrc, renderScale, ...props }) => {

	const tileScale = 0.5;

	return (
		<Sprite
			image={imageSrc}
			scale={ tileScale * renderScale }
			x= { (x * 256 * tileScale) * renderScale }
			y= { (y * 256 * tileScale) * renderScale }
			anchor={0.5}
			interactive={ props.onClick ? true : false }
			pointerdown={ (props.onClick ?? null) }
			tint={ props.tint ?? 0xffffff }
			angle={ props.angle ?? 0 }
			alpha={ props.alpha ?? 1 }
			filters={props.filters ?? []}
			// {...props}
		/>
	);
}

const BoardMeeple = ({ tileX, tileY, meepleX, meepleY, imageSrc, renderScale, ...props }) => {

	const tileScale = 0.5;

	return (
		<Sprite
			image={imageSrc}
			scale={ tileScale * renderScale }
			x= { ((tileX + meepleX) * 256 * tileScale) * renderScale }
			y= { ((tileY + meepleY) * 256 * tileScale) * renderScale }
			anchor={ {x: 0.5, y: 1} }
			interactive={ props.onClick ? true : false }
			pointerdown={ (props.onClick ?? null) }
			tint={ props.tint ?? 0xffffff }
			angle={ props.angle ?? 0 }
		/>
	);
}

const fieldShader = `

		precision mediump float;

		varying vec2 vTextureCoord;

		uniform sampler2D uSampler;

		uniform vec4 col1;
		uniform vec4 col2;
		uniform vec4 col3;
		uniform vec4 col4;
		uniform vec4 col5;
		uniform vec4 col6;
		uniform vec4 col7;
		uniform vec4 col8;

		void main(){

			vec4 fg = texture2D( uSampler, vTextureCoord );

	        float ratio = 80.0;

	        vec2 pixel = gl_FragCoord.xy;
	    
	        float diagonal = (pixel.x + pixel.y) / 2.0;
	        
	        float x = mod( diagonal, ratio);
	        
	        float first4Ratio = ratio / 2.0;

	        float first4Check = step(x, first4Ratio);

	        float first4SubCheck = step(x, first4Ratio / 2.0);
	        float first4SubSubCheck1 = step(x, first4Ratio / 4.0);
	        float first4SubSubCheck2 = step(x, 3.0 * (first4Ratio / 4.0));

	        float second4SubCheck = step(x, first4Ratio + first4Ratio / 2.0);
	        float second4SubSubCheck1 = step(x, first4Ratio + first4Ratio / 4.0);
	        float second4SubSubCheck2 = step(x, first4Ratio + 3.0 * (first4Ratio / 4.0));
	        
	        
	        gl_FragColor = first4Check * (
	                          first4SubCheck * ( first4SubSubCheck1 * col1 + (1.0 - first4SubSubCheck1) * col2 ) +
	                          ( 1.0 - first4SubCheck ) * ( first4SubSubCheck2 * col3 + (1.0 - first4SubSubCheck2) * col4 )
	                        )
	                        + (1.0 - first4Check) * (
	                          second4SubCheck * ( second4SubSubCheck1 * col5 + (1.0 - second4SubSubCheck1) * col6 ) +
	                          ( 1.0 - second4SubCheck ) * ( second4SubSubCheck2 * col7 + (1.0 - second4SubSubCheck2) * col8 )
	                        );

	        gl_FragColor *= ( 1.0 - step( fg.a, 0.01 ) );
		}

`

class PixiBoard extends React.Component
{
	viewportWidthRef = 1015;
	viewportHeightRef = 833;

	defaultViewportSnapZoomWidth = 1015;
	defaultViewportSnapZoomHeight = 833;

	state =
	{
	    currentTilePlacementHash: null,
	    currentPlacementRotationIndex: 0,
	    currentMeeplePlacementHash: null,
	    currentMeeplePlacementIndex: -1
	};

	fieldDisplayFilterIndex = 0;
	fieldDisplayFilters = [];
	fieldDisplayFilterUniforms = [];


	constructor(props)
	{
		super(props);

		this.viewportRef = React.createRef();
	}

	componentDidMount()
	{
		// Setup default initial viewport FOV and zoom level

		if( this.viewportRef.current )
		{
			this.viewportRef.current.snapZoom({ width: this.defaultViewportSnapZoomWidth, height: this.defaultViewportSnapZoomHeight, removeOnComplete: true, time: 1 });
			this.viewportRef.current.snap( 0, 0, { removeOnComplete: true, time: 1 });
		}
		

		// Register for key down event listening
		window.addEventListener('keydown', this.handleKeyDown);
	}

	componentWillUnmount()
	{
		// Clean up the keydown event listening
		window.removeEventListener('keydown', this.handleKeyDown);
	}

	convertIntegerColourToNormalisedRGB( integerColour, alpha )
	{
		// convert to hex based string
		let h = integerColour.toString(16);

		// convert to rgb
		let r = 0, g = 0, b = 0;

		if (h.length === 6)
		{
			r = "0x" + h[0] + h[1];
			g = "0x" + h[2] + h[3];
			b = "0x" + h[4] + h[5];
		}

		return [ +r / 255.0, +g / 255.0, +b / 255.0, alpha ];
	}

	getFieldDisplayFilter( teamColours, alpha = 1 )
	{
		let fieldFilter = null;
		let fieldFilterUniforms = null;

		if( this.fieldDisplayFilterIndex < this.fieldDisplayFilters.length )
		{
			// Use a cached filter
			fieldFilter = this.fieldDisplayFilters[ this.fieldDisplayFilterIndex ];
			fieldFilterUniforms = this.fieldDisplayFilterUniforms[ this.fieldDisplayFilterIndex ];
		}
		else
		{
			// Create a new filter & uniforms
			fieldFilterUniforms = { col1 : [ 1,1,1,0 ], col2 : [ 1,1,1,0 ], col3 : [ 1,1,1,0 ], col4 : [ 1,1,1,0 ], col5 : [ 1,1,1,0 ], col6 : [ 1,1,1,0 ], col7 : [ 1,1,1,0 ], col8 : [ 1,1,1,0 ] };
			fieldFilter = new Filter(null, fieldShader, fieldFilterUniforms);

			this.fieldDisplayFilters.push( fieldFilter );
			this.fieldDisplayFilterUniforms.push( fieldFilterUniforms );
		}

		this.fieldDisplayFilterIndex++;

		if(!teamColours)
		{
			// Default to white if no colours given
			teamColours = [ 0xffffff ];
		}

		let stripesPerColour = Math.floor( 8.0 / teamColours.length );
		let teamColourIndex = 0;
		let lastColourSwitchIndex = 0;

		for( let idx=0; idx < 8; idx ++ )
		{
			if(idx - lastColourSwitchIndex === stripesPerColour )
			{
				teamColourIndex++;
				lastColourSwitchIndex = idx;
			}

			if( teamColourIndex < teamColours.length )
			{
				fieldFilterUniforms['col' + (idx + 1)] = this.convertIntegerColourToNormalisedRGB( teamColours[ teamColourIndex ], alpha );
			}
			else
			{
				fieldFilterUniforms['col' + (idx + 1)] = [0,0,0,0];
			}
		}

		return fieldFilter;
	}

	onTilePlacementClick = (tileHash) => 
    {
    	this.setState( { currentTilePlacementHash: tileHash } );
    	this.setState( { currentPlacementRotationIndex: 0 });
    }

    onTilePlacementRotateClick = () =>
    {
    	let numValidRotations = this.props.validTilePlacements[this.state.currentTilePlacementHash].length;

    	this.setState( { currentPlacementRotationIndex: (this.state.currentPlacementRotationIndex + 1) % numValidRotations } );
    }

    onTilePlacementCancelClick = () =>
    {
    	this.clearTileTurnData();
    }

    onTilePlacementConfirmClick = () =>
    {
    	// Call to server to inform of placement decision
    	emit.playerTilePlacement( { tileHash: this.state.currentTilePlacementHash, rotationIndex: this.state.currentPlacementRotationIndex } );

    	this.clearTileTurnData();
    }

    clearTileTurnData = () =>
    {
    	this.setState( { currentTilePlacementHash: null } );
    }


    onMeeplePlacementClick = (tileHash, placementIndex) => 
    {
    	this.setState( { currentMeeplePlacementHash: tileHash } );
    	this.setState( { currentMeeplePlacementIndex: placementIndex });

    	console.log("onMeeplePlacementClick, tile : " + utils.hashToCoordString(tileHash) + ", placement index : " + placementIndex );
    }

    onMeeplePlacementCancelClick = () =>
    {
    	this.clearMeepleTurnData();
    }

    onMeeplePlacementConfirmClick = () =>
    {
    	// Call to server to inform of placement decision
    	emit.playerMeeplePlacement( { tileHash: this.state.currentMeeplePlacementHash, placementIndex: this.state.currentMeeplePlacementIndex, meepleType: 0 } );

    	this.clearMeepleTurnData();
    }

    clearMeepleTurnData = () =>
    {
    	this.setState( { currentMeeplePlacementHash: null } );
    	this.setState( { currentMeeplePlacementIndex: -1 } );
    }


	handleKeyDown = (event) =>
	{
		// W : 87
		// A : 65
		// S : 83
		// D : 68

		// console.log('A key was pressed', event.keyCode);

		let movementSpeed = 25;

		if( event.keyCode === 65 )		// LEFT
		{
			this.viewportRef.current.snap( this.viewportRef.current.center.x + movementSpeed, this.viewportRef.current.center.y, { removeOnComplete: true, time: 100 });
		}
		else if( event.keyCode === 68 )	// RIGHT
		{
			this.viewportRef.current.snap( this.viewportRef.current.center.x - movementSpeed, this.viewportRef.current.center.y, { removeOnComplete: true, time: 100 });
		}
		else if( event.keyCode === 87 )	// UP
		{
			this.viewportRef.current.snap( this.viewportRef.current.center.x, this.viewportRef.current.center.y + movementSpeed, { removeOnComplete: true, time: 100 });
		}
		else if( event.keyCode === 83 )	// DOWN
		{
			this.viewportRef.current.snap( this.viewportRef.current.center.x, this.viewportRef.current.center.y - movementSpeed, { removeOnComplete: true, time: 100 });
		}
	};


    getColoursWithIndexes( colours, indexes )
    {
    	let colourList = [];

    	for( let idx=0; idx < indexes.length; idx++ )
    	{
    		colourList.push( colours[ indexes[idx] ] );
    	}

    	return colourList;
    }


	render()
	{
		const {
			stageRenderData,
			validTilePlacements,
			validMeeplePlacements,
			boardTiles,
			boardMeeples,
			currentTileSprite,
			playerTeamColour,
			playerData,
			interactive,
			fieldStateData,
			teamColours
		} = this.props;

		const renderScale = stageRenderData.scale;

		const {
			currentTilePlacementHash,
			currentPlacementRotationIndex,
			currentMeeplePlacementHash,
			currentMeeplePlacementIndex
		} = this.state;

		// Reset the field filter display index
		this.fieldDisplayFilterIndex = 0;


		// Compile the current state of the board tiles
		let boardTileSprites = [];
		let tileData;
		let tileCoord;
		for( let tileHash in boardTiles )
		{
			tileData = boardTiles[tileHash];
			tileCoord = utils.hashToCoord(tileHash);

			boardTileSprites.push(

				<BoardTile
					key={"tile_" + boardTileSprites.length }
					imageSrc={"./tiles/" + tileData.sprite + ".png" }
					renderScale={ renderScale }
					x={ tileCoord.x }
					y={ -1 * tileCoord.y }
					angle={ tileData.rotation * 90 }
			    />
			);
		}


		let fieldStateSprites = [];

		if( fieldStateData )
		{
			for( let fieldIdx=0; fieldIdx < fieldStateData.length; fieldIdx ++ )
			{
				let fieldData = fieldStateData[ fieldIdx ];
				let fieldFilter = this.getFieldDisplayFilter( this.getColoursWithIndexes( teamColours, fieldData.winningTeamIndexes ), 0.5 );

				for( let tileIdx=0; tileIdx < fieldData.tileData.length; tileIdx++ )
				{
					let fieldTileData = fieldData.tileData[tileIdx];

					tileCoord = utils.hashToCoord( fieldTileData.tile );

					fieldStateSprites.push(

						<BoardTile
							key={"fieldtile_" + fieldStateSprites.length }
							imageSrc={"./tiles/" + fieldTileData.tileSpriteData.sprite + ".png" }
							renderScale={ renderScale }
							x={ tileCoord.x }
							y={ -1 * tileCoord.y }
							angle={ fieldTileData.tileSpriteData.rotation * 90 }
							alpha={0.5}
							filters={[fieldFilter]}
					    />

					);
				}
			}
		}





		let boardMeepleSprites = [];

		if( boardMeeples )
		{
			// console.log("BOARD MEEPLES!");
			// console.log(boardMeeples);

			// Construct the meeple placement elements
			
			for( let teamIndex in boardMeeples )
			{
				let teamMeeples = boardMeeples[teamIndex];

				if(!teamMeeples)
				{
					continue;
				}

				const teamColour = playerData.playersJoined[teamIndex].colourHex;

				console.log("Team [" + teamIndex + "] board meeples [" + teamMeeples.length + "] :");
				console.log(teamMeeples);

				for( let idx=0; idx < teamMeeples.length; idx++ )
				{
					const tileCoords = utils.hashToCoord( teamMeeples[idx].tileHash );
					const localMeepleCoords = teamMeeples[idx].localMeepleCoords;

					boardMeepleSprites.push(
					    <BoardMeeple
					    	key={"boardMeeple" + boardMeepleSprites.length }
							imageSrc={"./ui/meeple-standard.png"}
							tint={teamColour}
							renderScale={ renderScale }
							tileX={ tileCoords.x }
							tileY={ -1 * tileCoords.y }
							meepleX={ localMeepleCoords.x }
							meepleY={ localMeepleCoords.y * -1 }
					    />
					);
				}
			}
		}


		


		// Compile the available turn placement sprites
		let placementTileOptionSprites = [];
		let tilePlacementPreview = null;
		let tileInteractionContent = [];

		if( validTilePlacements )
		{
			for( let tileHash in validTilePlacements )
			{
				let placementCoords = utils.hashToCoord( tileHash );

				placementTileOptionSprites.push(
					<BoardTile
						key={"tileOption" + placementTileOptionSprites.length }
						imageSrc={"./empty_tile_slot.png"}
						renderScale={ renderScale }
						x={ placementCoords.x }
						y={ -1 * placementCoords.y }
						// scale={0.5}
						onClick={ ()=> { this.onTilePlacementClick(tileHash); } }
				    />
				);
			}


			//
			if( currentTilePlacementHash )
			{
				let placementCoords = utils.hashToCoord( currentTilePlacementHash );
				let currentPlacementRotationsData = validTilePlacements[currentTilePlacementHash];
				let numValidRotations = currentPlacementRotationsData.length;

				// console.log( `placementCoords : ${placementCoords}, currentPlacementRotationsData : ${currentPlacementRotationsData}, numValidRotations : ${numValidRotations}`);

				// Add tile preview
				tilePlacementPreview = (

					<BoardTile
						imageSrc={"./tiles/" + currentTileSprite + ".png"}
						renderScale={ renderScale }
						x={ placementCoords.x }
						y={ (-1 * placementCoords.y )}
						tint={0xaaaaaa}
						angle={ currentPlacementRotationsData[ currentPlacementRotationIndex ] * 90 }
				    />
				);


				// Add the confirm button
				tileInteractionContent.push (

					<BoardTile
						key={ "confirm button" }
						imageSrc={"./ui/button-tick.png"}
						renderScale={ renderScale }
						x={ placementCoords.x + 0.6 }
						y={ (-1 * placementCoords.y) - 0.6 }
						onClick={ ()=>
						{
							// console.log("Confirmed placement");
							this.onTilePlacementConfirmClick();
						} }
				    />
				);

				// Add the cancel button
				tileInteractionContent.push (
					<BoardTile
						key={ "cancel button" }
						imageSrc={"./ui/button-cross.png"}
						renderScale={ renderScale }
						x={ placementCoords.x - 0.6 }
						y={ (-1 * placementCoords.y) - 0.6 }
						onClick={ ()=>
						{
							// console.log("Cancel placement");
							this.onTilePlacementCancelClick();
						} }
				    />
				);

				// Add the rotate button
				if(numValidRotations > 1)
				{
					tileInteractionContent.push (
						<BoardTile
							key={ "rotate button" }
							imageSrc={"./ui/button-rotate.png"}
							renderScale={ renderScale }
							x={ placementCoords.x }
							y={ (-1 * placementCoords.y) - 0.6 }
							onClick={ ()=>
							{
								// console.log("Rotate placement");
								this.onTilePlacementRotateClick();
							} }
					    />
					);
				}
			}
		}



		// Compile the available meeple placement sprites
		let placementMeepleOptionSprites = [];
		let meepleInteractionContent = [];

		if( validMeeplePlacements )
		{
			for( let tileHash in validMeeplePlacements )
			{
				let tileCoords = utils.hashToCoord( tileHash );
				let meeplePlacements = validMeeplePlacements[ tileHash ];

				for( let idx=0; idx < meeplePlacements.length; idx++ )
				{
					const localMeepleCoords = meeplePlacements[idx].localMeepleCoords;

					placementMeepleOptionSprites.push(
						<BoardMeeple
							key={"meepleOption" + placementMeepleOptionSprites.length }
							imageSrc={"./ui/meeple-standard-empty.png"}
							renderScale={ renderScale }
							tileX={ tileCoords.x }
							tileY={ -1 * tileCoords.y }
							meepleX={ localMeepleCoords.x }
							meepleY={ localMeepleCoords.y * -1 }
							onClick={ ()=> { this.onMeeplePlacementClick(tileHash, idx); } }
					    />
					);
				}
			}

			if( currentMeeplePlacementHash && currentMeeplePlacementIndex >= 0 )
			{
				let tileCoords = utils.hashToCoord( currentMeeplePlacementHash );
				const localMeepleCoords = validMeeplePlacements[currentMeeplePlacementHash][currentMeeplePlacementIndex].localMeepleCoords;

				// Add meeple preview
				meepleInteractionContent.push (

				    <BoardMeeple
				    	key={ "meeple preview" }
						imageSrc={"./ui/meeple-standard.png"}
						tint={playerTeamColour}
						renderScale={ renderScale }
						tileX={ tileCoords.x }
						tileY={ -1 * tileCoords.y }
						meepleX={ localMeepleCoords.x }
						meepleY={ localMeepleCoords.y * -1 }
				    />
				);


				// Add the confirm button
				meepleInteractionContent.push (

					<BoardTile
						key={ "confirm button" }
						imageSrc={"./ui/button-tick.png"}
						renderScale={ renderScale }
						x={ tileCoords.x + 0.6 }
						y={ (-1 * tileCoords.y) - 0.6 }
						onClick={ ()=>
						{
							// console.log("Confirmed placement");
							this.onMeeplePlacementConfirmClick();
						} }
				    />
				);

				// Add the cancel button
				meepleInteractionContent.push (
					<BoardTile
						key={ "cancel button" }
						imageSrc={"./ui/button-cross.png"}
						renderScale={ renderScale }
						x={ tileCoords.x - 0.6 }
						y={ (-1 * tileCoords.y) - 0.6 }
						onClick={ ()=>
						{
							// console.log("Cancel placement");
							this.onMeeplePlacementCancelClick();
						} }
				    />
				);
			}
		}


		

		return (

			<Viewport
				ref={this.viewportRef}
				plugins={ ["drag", "pinch", "wheel", "clampZoom", "decelerate" ] }	// "decelerate"
				screenWidth={ this.viewportWidthRef * renderScale }
				screenHeight={ this.viewportHeightRef * renderScale }
				interactive={ interactive }
				interactiveChildren={ interactive }
			>

		 		{boardTileSprites}

		 		{fieldStateSprites}
				    
				{placementTileOptionSprites}
				{tilePlacementPreview}

				{placementMeepleOptionSprites}

				{boardMeepleSprites}

				{tileInteractionContent}
				{meepleInteractionContent}

			</Viewport>

		);
	}
}

export default PixiBoard;