import React from 'react';
import PixiScaledSprite from "./PixiScaledSprite"
import PixiScaledText from "./PixiScaledText"
import PixiEndGamePlayerInfoRow from "./PixiEndGamePlayerInfoRow"
import { Container } from '@inlet/react-pixi';

class PixiEndGameOverlay extends React.Component
{
	panelWidth = 450;

	textStyle = {
	  align: "center",
	  fontWeight: "normal",
	  fill: "#111111"
	};

	// constructor(props)
	// {
	// 	super(props);
	// }

	render()
	{
		const stageRenderData = this.props.stageRenderData;
		const playerData = this.props.playerData;
		const clientId = this.props.clientId;

		const numPlayers = playerData.playersJoined.length;

		// Combine the scores and player name info into single objects in an array
		let endGamePlayerData = [];
		let isClientPlaying = false;

		for(let idx=0; idx < numPlayers; idx ++ )
		{
			if( playerData.playersJoined[idx].socketID === clientId )
			{
				isClientPlaying = true;
			}

			endGamePlayerData.push(
				{
					username: playerData.playersJoined[idx].username,
					score: playerData.scores[idx],
					isClient: playerData.playersJoined[idx].socketID === clientId,
					isHuman: !playerData.playersJoined[idx].isBot,
					colourHex: playerData.playersJoined[idx].colourHex
				}
			);
		}

		// Sort player data list by scores, highest to lowest
		endGamePlayerData.sort( (pd1, pd2) => { return pd2.score - pd1.score; } );



		

		let uiRightPanelContent = [];
		const rowSpacing = 5;
		const playerRowHeight = 80;

		const panelHeight = numPlayers * playerRowHeight;

		let yOffset = -panelHeight / 2;
		let finishingPosition = 0;
		let playerFinishingPosition;

		for( let idx=0; idx < numPlayers; idx++ )
		{
			const currentPlayerData = endGamePlayerData[idx];

			if( idx > 0 && currentPlayerData.score < endGamePlayerData[idx-1].score )
			{
				finishingPosition++;
			}

			uiRightPanelContent.push(
	 	   		<PixiEndGamePlayerInfoRow
	 	   			key={ "player-" + idx }
	 	   			stageRenderData={stageRenderData}
	 	   			x= { (stageRenderData.refWidth / 2 ) + (this.panelWidth / 2) }
					y= { (stageRenderData.refHeight / 2) + yOffset + (playerRowHeight/2) }
					width = {this.panelWidth}
					height = {playerRowHeight}
	 	   			playerName={currentPlayerData.username}
	 	   			teamColour={currentPlayerData.colourHex}
	 	   			playerScore={currentPlayerData.score}
	 	   			isClientPlayerRow={currentPlayerData.isClient}
	 	   			isHuman={ currentPlayerData.isHuman }
	 	   			finishingPosition={this.finishPositionToText(finishingPosition)}
	 	   			/>
	 	   	);

	 	   	if( currentPlayerData.isClient )
	 	   	{
	 	   		playerFinishingPosition = finishingPosition;
	 	   	}

	 	   	yOffset += playerRowHeight + rowSpacing;
		}

		return (

			<Container>

				<PixiScaledSprite
					imageSrc={"./ui/white_tile.png" }
					stageRenderData={stageRenderData}
					x= { 0 }
					y= { 0 }
					width = {stageRenderData.refWidth}
					height = {stageRenderData.refHeight}
					anchor={ {x: 0, y: 0} }
					tint={0x111111}
					alpha = { 0.5 }
		 	   	/>

		 	   	{ isClientPlaying &&
		 	   	(
		 	   		<PixiScaledText
						text={ "You are " + this.finishPositionToText(playerFinishingPosition) + (playerFinishingPosition === 0 ? "!" : "") }
						stageRenderData={ stageRenderData }
						x= { stageRenderData.refWidth / 2 }
						y= { 100 }
						// width = {400}
						// height = {40}
						anchor={ {x: 0.5 , y: 0.5 }}
						fontSize={75}
						style={this.textStyle}
					/>
				)}
		 	   	

				{ uiRightPanelContent }
			</Container>

		);
	}

	finishPositionToText(finishingPosition)
	{
		switch(finishingPosition)
		{
			case 0:
				return "1st";
			case 1:
				return "2nd";
			case 2:
				return "3rd";
			case 3:
				return "4th";
			case 4:
				return "5th";
			case 5:
				return "6th";
			case 6:
				return "7th";
			case 7:
			default:
				return "8th";
		}
	}
}

export default PixiEndGameOverlay;