import { io } from 'socket.io-client';
import { socketUrl } from '../../env';
import events from 'events';

// Copy of the 'GAME_STATE' values from the gameModel.js in the server-side code.
export const GAME_STATE =
{ 
    PLACING_TILE: 0,
    PLACING_MEEPLE: 1,
    END_GAME_PHASE: 2,
    GAME_OVER: 3

    // Future states...
    // MOVING_DRAGON: 3,
    // MOVING_FAIRY: 4
};

export default class GameStateManager
{
	eventEmitter = new events.EventEmitter();

	socket = undefined;
	socketClientID = undefined;
	socketConnectedRoomId = undefined;

	socketRoomOptions = undefined;
	socketRoomHasStarted = undefined;

	numGameStateChangeListeners = 0;
	timerID = null;

	onRoomChatMessageCallbacks = [];
	onGameInfoConsoleMessageCallbacks = [];

	gameStateDataDirty = false;

	// Room Game State Data

	gameStateData = {

		hasStarted: false,
		gameState: -1,
		boardTiles: null,
		boardMeeples: null,
		boardFields: null,
		playerData: null,
		currentTileSprite: null,
		numTilesRemaining: null,
		timerData: null,
		isPlayerTurn: false,
		validTilePlacements: null,
		validMeeplePlacements: null
	};


	createNewRoomSocket( tokenData, onRoomConnect, onConnectionError )
	{
	  console.log("CREATE NEW ROOM");

	  this.resetStateData();

	  // Open a new socket io connection on the server and a new Room

	  this.socket = io(`${socketUrl}`,
	  {
	    transports: ['websocket'],	// disable HTTP long-polling
	    query: {
	      action: 'create',
	      tokenData: JSON.stringify(tokenData)
	    }
	  });

	  this.initListeners( onRoomConnect, onConnectionError );
	}


	joinExistingRoomSocket( tokenData, roomId, onRoomConnect, onConnectionError )
	{
	  console.log("JOIN ROOM");

	  this.resetStateData();

	  // Attempt to join an existing room and open a socket io connection

	  this.socket = io(`${socketUrl}`,
	  {
	    transports: ['websocket'],	// disable HTTP long-polling
	    query: {
	      action: 'join',
	      tokenData: JSON.stringify(tokenData),
	      roomId: roomId
	    }
	  });

	  this.initListeners( onRoomConnect, onConnectionError );
	}


	createBugReportPlaybackSocket( tokenData, bugReportId, onRoomConnect, onConnectionError, skipToTileIndex = 0, skipToFeatureResolveIndex = 0, playbackTurnSpeed = 2 )
	{
	  this.resetStateData();

	  // Open a new socket io connection on the server and a new Room for bug report playback

	  this.socket = io(`${socketUrl}`,
	  {
	    transports: ['websocket'],	// disable HTTP long-polling
	    query: {
	      action: 'debug',
	      tokenData: JSON.stringify(tokenData),
	      bugReportId: bugReportId,
	      skipToTileIndex: skipToTileIndex,
	      skipToFeatureResolveIndex: skipToFeatureResolveIndex,
	      playbackTurnSpeed: playbackTurnSpeed
	    }
	  });

	  this.initListeners( onRoomConnect, onConnectionError );
	}


	/* 														*/
	/* Data / Event Callback Register/Unregister functions 	*/
	/* 														*/

	onRoomErrorRegister( callback )
	{
		this.eventEmitter.on( 'onRoomError', callback );
	}

	onRoomErrorUnregister( callback )
	{
		this.eventEmitter.removeListener( 'onRoomError', callback );
	}

	onGameStartRegister( callback )
	{
		this.eventEmitter.on( 'onGameStarted', callback );
	}

	onGameStartUnregister( callback )
	{
		this.eventEmitter.removeListener( 'onGameStarted', callback );
	}

	onGameStateChangedRegister( callback )
	{
		this.numGameStateChangeListeners++;

		this.eventEmitter.on( 'onGameStateChanged', callback );

		// Send the current game state data
		callback( this.gameStateData );

		if( !this.timerID )
		{
			this.timerID = setInterval(
				() => this._tick(),
				100
			);
		}
	}

	onGameStateChangedUnregister( callback )
	{
		this.numGameStateChangeListeners--;

		this.eventEmitter.removeListener( 'onGameStateChanged', callback );

		if( this.numGameStateChangeListeners <= 0 )
		{
			// No more active listeners, so stop the tick interval
			clearInterval( this.timerID );

			this.timerID = null;
		}
	}

	_tick()
	{
		// console.log("state change tick... Dirty", this.gameStateDataDirty );

		if( this.gameStateDataDirty )
		{
			// console.log( "GAME STATE DATA DIRTY - EMITTING LATEST" );

			this.eventEmitter.emit( 'onGameStateChanged', this.gameStateData );

			this.gameStateDataDirty = false;
		}
	}
	


	resetStateData()
	{
		if( this.socket )
		{
			this.socket.close();
		}

		this.socketClientID = undefined;
		this.socketConnectedRoomId = undefined;
		this.socketRoomOptions = undefined;
		this.socketRoomHasStarted = undefined;
	}

	addOnRoomChatMessageCallback( callback )
	{
		this.onRoomChatMessageCallbacks.push( callback );
	}

	broadcastRoomChatMessage( message )
	{
		for( let idx=0; idx < this.onRoomChatMessageCallbacks.length; idx++ )
		{
			this.onRoomChatMessageCallbacks[idx]( message );
		}
	}

	addOnGameInfoConsoleMessageCallback( callback )
	{
		this.onGameInfoConsoleMessageCallbacks.push( callback );
	}

	broadcastGameInfoConsoleMessage( message )
	{
		for( let idx=0; idx < this.onGameInfoConsoleMessageCallbacks.length; idx++ )
		{
			this.onGameInfoConsoleMessageCallbacks[idx]( message );
		}
	}

	initListeners( onRoomConnect, onConnectionError )
	{
		this.initSocketConnectionStatusListeners( this.socket, onRoomConnect, onConnectionError );

		this.initGameStateChangeListeners( this.socket );

		this.initGameErrorListeners( this.socket );
	}


	initSocketConnectionStatusListeners ( socket, onRoomConnect, onConnectionError )
	{
		socket.on('connect', () =>
		{
			console.log("Socket Connected :: client socketID : " + socket.id );
		});

		socket.on('server-process-id', ({ processID }) =>
		{
			console.log('server-process-id', processID);
		});

		// socket.on('new-socket-connection', ({ newSocket }) =>
		// {
		// 	console.log('new-socket-connection !! - welcome to socket ID : ', newSocket );
		// });

		socket.on('success-room-entered', ({ roomId, options, gameStarted }) => {

			console.log('Room Connection Successfully initialised. gameStarted : ' + gameStarted );

			this.socketConnectedRoomId = roomId;
			this.socketClientID = socket.id;
			this.socketRoomOptions = options;
			this.socketRoomHasStarted = gameStarted;

			if( gameStarted )
			{
				this.eventEmitter.emit( 'onGameStarted' );
			}

			onRoomConnect( roomId );
		});

		socket.on('connect_error', function(data)
		{
			// data here should be an Error object sent by server
			console.log("On connection_error", data);

			socket.close();

			onConnectionError({
			    title: 'Game Session Verification Failed',
			    content: 'Please refresh page and try again.'
			});
		});


		socket.on('error-room-already-full', () =>
		{
			console.log('Error: Room is already full.');

			socket.close();

			onConnectionError({
			    title: 'ROOM FULL',
			    content: 'Sorry, this room is already full.'
			});
		});

		socket.on('error-previously-kicked-from-room', () =>
		{
			console.log('Error: Player previously kicked from room.');

			socket.close();

			onConnectionError({
			    title: 'PREVIOUSLY KICKED',
			    content: 'You were kicked from this room.'
			});
		});

		socket.on('error-user-already-in-room', () =>
		{
			console.log('Error: Player is already in this room.');

			socket.close();

			onConnectionError({
			    title: 'USER ALREADY IN ROOM',
			    content: 'It appears that you are already playing in this room.'
			});
		});

		socket.on('error-user-reached-max-room-limit', () =>
		{
			console.log('Error: User has reached ther max room limit.');

			socket.close();

			onConnectionError({
			    title: 'MAX GAME LIMIT REACHED',
			    content: 'You already have the maximum number of active games in progress. You need to wait for one of those to finish before starting or joining another.'
			});
		});

		socket.on('error-room-already-started', () =>
		{
			console.log('error-room-already-started');

			socket.close();

			onConnectionError({
			    title: 'GAME ALREADY STARTED',
			    content: 'Sorry, this room has already started playing.'
			});
		});

		socket.on('error-room-not-found', () =>
		{
			console.log('Error : Create a room first!');
			
			socket.close();

			onConnectionError({
			    title: 'ROOM NOT FOUND',
			    content: 'Sorry, requested Room does not exist. Create a New Room or enter the correct ROOM ID'
			});
		});

		socket.on('Error: Room already created. Join the room!', () =>
		{
			console.log('Error: Create a new room again or Join existing one!');

			socket.close();
			
			onConnectionError({
			    title: 'ROOM ALREADY PRESENT',
			    content: 'Sorry, requested Room already exists, Join the existing room or Create a new room again'
			});
		});

		socket.on('error-set-team-colour-failed', () =>
		{
			console.log('Error: Team colour not available.');

			onConnectionError({
			    title: 'COLOUR UNAVAILABLE',
			    content: 'Team colour not available. Please choose another.'
			});
		});

		socket.on('host-kicked-player-from-room', () =>
		{
			console.log('Host kicked player from the room.');

			socket.close();

			let currentLocation = window.location.href;
			let hashIndex = currentLocation.indexOf("#");
			let locationMinusRoomHash = currentLocation.substring(0, hashIndex);

			window.location.replace( locationMinusRoomHash );

			onConnectionError({
			    title: 'PLAYER KICKED',
			    content: 'The host kicked you from the room.'
			});
		});
	};


	initGameStateChangeListeners( socket )
	{
		socket.on('room-settings-changed', ({ options }) =>
		{
			this.socketRoomOptions = options;

			this.gameStateDataDirty = true;

		});

		socket.on('game-start', message =>
		{
			this.socketRoomHasStarted = true;

			this.eventEmitter.emit( 'onGameStarted' );
		});

		socket.on('chat-text-received', ( chatTextData ) =>
		{
			// {username: 'TomLocal', chatText: 'FARTS'}
      		// {chatBotMessageID: 'inappropriate-language'}

      		if( chatTextData.chatBotMessageID )
      		{
      			// A message from the chat bot!
      			let chatBotMessage = "";

      			switch( chatTextData.chatBotMessageID )
      			{
	      			case 'inappropriate-language':
	      				chatBotMessage = "Please avoid inappropriate language.";
	      				break;

	      			case 'spectator-cant-chat':
	      				chatBotMessage = "Spectators can't use the room chat.";
	      				break;

	      			case 'chat-temp-blocked':
	      				chatBotMessage = "Chat usage temporarily blocked.";
	      				break;

	      			default:
	      				chatBotMessage = chatTextData.chatBotMessageID;
	      				break;
      			}

      			this.broadcastRoomChatMessage( { chatBotMessage: chatBotMessage } );
      		}
      		else
      		{
      			this.broadcastRoomChatMessage( chatTextData );
      		}
		});

	    socket.on('player-placed-tile-info', (data) =>
		{
			this.broadcastGameInfoConsoleMessage( data.message );
		});

		socket.on('player-placed-meeple-info', (data) =>
		{
			this.broadcastGameInfoConsoleMessage( data.message );
		});

		socket.on('player-away', (data) =>
		{
			this.broadcastGameInfoConsoleMessage( data.message );
		});

		socket.on('player-reconnected', (data) =>
		{
			this.broadcastGameInfoConsoleMessage( data.message );
		});

		socket.on('spectator-joined', (data) =>
		{
			this.broadcastGameInfoConsoleMessage( data.message );
		});

		socket.on('spectator-disconnected', (data) =>
		{
			this.broadcastGameInfoConsoleMessage( data.message );
		});

		socket.on('player-disconnected', (data) =>
		{
			this.broadcastGameInfoConsoleMessage( data.message );
		});
	  

		socket.on('game-state-data-changed', (data) =>
	    {
	    	if( !data )
	    	{
	    		return;
	    	}

	    	if( data.hasStarted !== undefined )
	    	{
	    		this.gameStateData.hasStarted = data.hasStarted;
	    	}

	    	if( data.gameState !== undefined )
	    	{
	    		this.gameStateData.gameState = data.gameState;
	    	}

	    	if( this.gameStateData.gameState === GAME_STATE.PLACING_TILE )
	    	{
	    		if( data.tileSprite !== undefined )
		    	{
		    		this.gameStateData.currentTileSprite = data.tileSprite;
		    	}
	    	}
	    	else
	    	{
				this.gameStateData.currentTileSprite = null;
	    	}
			
			if( data.numTilesRemaining !== undefined )
			{
				this.gameStateData.numTilesRemaining = data.numTilesRemaining;
			}
			
			if( data.timerData !== undefined )
			{
				this.gameStateData.timerData = data.timerData;
			}

			if( data.playerData !== undefined )
			{
				this.gameStateData.playerData = data.playerData;
			}
			
			if( data.boardData !== undefined )
			{
				this.gameStateData.boardMeeples = data.boardData.meeples;
				this.gameStateData.boardTiles = data.boardData.tiles;
				this.gameStateData.boardFields = data.boardData.fields;
			}

			if( data.validTilePlacements !== undefined )
			{
				this.gameStateData.validTilePlacements = data.validTilePlacements;
			}

			if( data.validMeeplePlacements !== undefined )
			{
				this.gameStateData.validMeeplePlacements = data.validMeeplePlacements;
			}

			this.gameStateDataDirty = true;

			console.log("EMIT game state change");
	    });
	}



	initGameErrorListeners( socket )
	{
		// onRoomResizeFail: 
		socket.on('Error: Room resize unavailable. Remove room clients first.', () =>
		{
			console.log('Error: Room resize unavailable. Remove room clients first.');

			this.eventEmitter.emit( 'onRoomError', {
				title: 'ROOM RESIZE UNAVAILABLE',
				content: 'The room already has more players than the requested room size change. Remove players before attempting to reduce the Max Room Size.'
			} );

		});
	}


}






