import React from 'react';
import Button from '../Utils/Button';
import { restUrl } from '../../env';
import { loggedInTokenData, WaitUntilLoggedIn } from '../Core/LoginManager';
import axios from 'axios';
import { gameStateManager } from '../../App';
import { Navigate } from "react-router-dom";
import BugReportRow from './BugReportRow';

export default class BugReports extends React.Component {
  state = {
    bugReports: []
  };

  async componentDidMount()
  {
    await WaitUntilLoggedIn( async () =>
    {
        await this.updateBugReportsList();
    });
  }

  async updateBugReportsList()
  {
    const response = await axios.post(`${restUrl}/admin/bugReports`, { tokenData: loggedInTokenData });

    if( response.data.success )
    {
      this.setState({ bugReports: response.data.data });
    }
  }

  render()
  {
    if ( gameStateManager.socketConnectedRoomId )
    {
      return (
        <Navigate to={"/#" + gameStateManager.socketConnectedRoomId } replace={true} />
      );
    }

    return ( 
      <div>

        <h4>Bug Reports</h4>
        <Button onClick={() =>
        {
          this.updateBugReportsList();
        }}>
          Refresh
        </Button>

        <br />
        <br />

        
        {this.state.bugReports.map(el => (
          
            <BugReportRow bugReportData={ el } key={ el.roomId + "" } />
        ))}
        
      </div>
    );
  }
}
