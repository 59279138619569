import React from 'react';
// import PixiScaledSprite from "./PixiScaledSprite"
// import PixiScaledText from "./PixiScaledText"
import PixiGamePlayerRow from "./PixiGamePlayerRow"
import { Container } from '@inlet/react-pixi';

class PixiGamePlayersInfoPanel extends React.Component
{
	panelWidth = 331;
	panelHeight = 372;

	// constructor(props)
	// {
	// 	super(props);
	// }

	render()
	{
		const stageRenderData = this.props.stageRenderData;
		const playerData = this.props.playerData;
		const clientId = this.props.clientId;

		const numPlayers = playerData.playersJoined.length;
		let clientPlayerIndex = 0;
		
		// Get index of client in the player list
		for( let idx=0; idx < numPlayers; idx++ )
		{
			if( playerData.playersJoined[idx].id === clientId )
			{
				clientPlayerIndex = idx;
				break;
			}
		}

		let uiRightPanelContent = [];
		const rowSpacing = 5;
		const playerRowHeight = 122;
		const standardRowHeight = numPlayers <= 4 ? 78 : ( this.panelHeight - playerRowHeight - ((numPlayers-1) * rowSpacing) ) / (numPlayers - 1);

		let yOffset = 2 + ((numPlayers-1) * (standardRowHeight)) + playerRowHeight + (numPlayers * rowSpacing); 

		for( let idx=0; idx < numPlayers; idx++ )
		{
			const index = (clientPlayerIndex + idx + 1) % numPlayers;
			const currentPlayerData = playerData.playersJoined[index];
			const playerName = currentPlayerData.username;
			const isClientPlayerRow = idx === numPlayers - 1;
			const rowHeight = isClientPlayerRow ? playerRowHeight : standardRowHeight;
			const playerScore = playerData.scores ? playerData.scores[ index ] : 0;
			const playerMeeples = playerData.meeples ? playerData.meeples[ index ] : [];

			let playerIconSprite;

			if( currentPlayerData.isBot )
			{
				playerIconSprite = "./ui/bot-icon.png";
			}
			else if( currentPlayerData.socketID )
			{
				playerIconSprite = "./ui/human-icon.png";
			}
			else
			{
				playerIconSprite = "./ui/human-away-icon.png";
			}

			yOffset -= rowHeight + rowSpacing;

			uiRightPanelContent.push(
	 	   		<PixiGamePlayerRow
	 	   			key={index.toString()}
	 	   			stageRenderData={stageRenderData}
	 	   			x= { stageRenderData.refWidth - 2 }
					y= { stageRenderData.refHeight - yOffset }
					width = {this.panelWidth}
					height = {rowHeight}
	 	   			playerName={playerName}
	 	   			teamColour={currentPlayerData.colourHex}
	 	   			playerScore={playerScore}
	 	   			playerMeeples={playerMeeples}
	 	   			isClientPlayerRow={isClientPlayerRow}
	 	   			playerIconSprite={ playerIconSprite }
	 	   			/>
	 	   	);

	 	   	// yOffset -= rowHeight + rowSpacing;
		}

		return (

			<Container>
				{ uiRightPanelContent }
			</Container>

		);
	}
}

export default PixiGamePlayersInfoPanel;