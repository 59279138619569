import React from 'react';

import SubmitBugPopup from '../Popups/SubmitBugPopup';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

class OverlayHelpButtons extends React.Component {

  render() {

    const
    {
      stageRenderData,
      // onReportABugPressed
    } = this.props;


    let playAreaOffsetX = ((window.innerWidth - stageRenderData.width) / 2);
    let playAreaOffsetY = stageRenderData.stageStyleTop;

    return (
      <div style={{
          width: (stageRenderData.scale * 40) + "px",
          height: (stageRenderData.scale * 60) + "px",
          position: "absolute",
          // backgroundColor: '#fdf4e0',
          left: playAreaOffsetX + "px",
          top: (playAreaOffsetY + ( 95 * stageRenderData.scale )) + "px"
        }}>
        
          <Popup trigger=
              {
                <button >
                  <img alt="bug report button" src="./ui/report-bug-button.png" style={{ width: ( 30 * stageRenderData.scale) + "px", height: ( 30 * stageRenderData.scale) + "px" }}   />
                </button>
              }
              modal
            >
              {close => (

                <SubmitBugPopup close={close} submit={ (bugText) => { console.log( "Submit Bug - ", bugText ); } } />
                
              )}
            </Popup>
        
      </div>
    );
  }
}

export default OverlayHelpButtons;
