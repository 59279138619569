import React from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';
import Button from '../Utils/Button';
import { minNumPlayers, maxNumPlayers, maxGameTimerMode, gameTimerModeNames } from '../../env';
import { emit, emitDebug } from '../Socket/game.Emitters';
import ToggleOption from '../Utils/ToggleOption';
import SliderOption from '../Utils/SliderOption';

export default class RoomDetails extends React.Component
{
  handleRoomSetupChange = event =>
  {
    if (event.target.name === 'max-players-limit')
    {
      const maxPlayersLimit = Number(event.target.value);
      if (typeof maxPlayersLimit === 'number' && maxPlayersLimit >= minNumPlayers && maxPlayersLimit <= maxNumPlayers)
      {
        emit.hostChangeMaxPlayers( maxPlayersLimit );
      }
    }
  };

  onGameTimerModeReduce = (event) =>
  {
    // event.preventDefault();

    const gameTimerMode = this.props.options.gameTimerMode - 1;
    if (gameTimerMode >= 0 && gameTimerMode <= maxGameTimerMode)
    {
      emit.hostChangeGameSpeed( gameTimerMode );
    }
  };

  onGameTimerModeIncrease = (event) =>
  {
    // event.preventDefault();

    const gameTimerMode = this.props.options.gameTimerMode + 1;
    if (gameTimerMode >= 0 && gameTimerMode <= maxGameTimerMode)
    {
      emit.hostChangeGameSpeed( gameTimerMode );
    }
  };

  startGamePressed = () =>
  {
    emit.hostToggleRoomStartGame();
  }

  render() {

    return (
      <>
        <div className="column-header">
          Game Settings { this.props.isRoomHost ? "[HOST]" : "" }
        </div>
        
        <div className="room-details-container">

          <div>
            <input type="text" defaultValue={window.location.href} readOnly style={{width : "220px" }} />
          </div>


          <div>
            Is Private?
            <input
              type="checkbox"
              disabled={ !this.props.isRoomHost }
              defaultChecked={ this.props.options.isPrivate }
              onClick={ () =>{ emit.hostSetRoomPrivateState( !this.props.options.isPrivate ); } } />
          </div>



          <h6>Game Timer </h6>

          {/* TODO: This inputgroup causes browser console warning */}

          <InputGroup>
            <Button
              onClick={this.onGameTimerModeReduce}
              disabled={ !this.props.isRoomHost }
            >
              {"<"}
            </Button>

            <InputGroup.Append>
              <FormControl
                readOnly
                type="string"
                name="game-timer-mode"
                aria-label="game-timer-mode"
                aria-describedby="game-timer-mode"
                value={ gameTimerModeNames[ this.props.options.gameTimerMode ] }
              />
            </InputGroup.Append>

            <InputGroup.Append>
              <Button
                onClick={this.onGameTimerModeIncrease}
                disabled={ !this.props.isRoomHost }
              >
                {">"}
              </Button>
            </InputGroup.Append>
          </InputGroup>
          <br />
          <h6>Max Players</h6>
          <InputGroup>
            <FormControl
              type="number"
              disabled={ !this.props.isRoomHost }
              placeholder="Default: 4"
              name="max-players-limit"
              aria-label="max-players-limit"
              aria-describedby="max-players-limit"
              value={this.props.options.maxPlayersLimit + ""}
              onChange={this.handleRoomSetupChange}
            />
            <InputGroup.Append>
              <InputGroup.Text id="players-prefix">players</InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </div>


        <div className="room-start-actions">

          <div className="is-ready-toggle">
            Ready?
            <input type="checkbox" defaultChecked={ this.props.isPlayerReady } onClick={ () =>{ emit.toggleIsReadyState(); } } />
          </div>


          { this.props.isRoomHost &&
            (
              <Button disabled={!this.props.areAllPlayersReady} onClick={ () =>{ this.startGamePressed(); } }>
                Start Game
              </Button>
          )}

        </div>


        { this.props.isRoomHost &&
          this.props.options &&
          process.env.NODE_ENV !== 'production' &&
          (
            <div style={{ border: '2px', borderColor: 'black', borderStyle: 'solid', padding: '15px', marginTop: '15px' }} >

              <h4>Dev Mode Options</h4>
              <br />

              <ToggleOption
                title="Skip Player Order Shuffle On Start?"
                currentState = { this.props.options.skipPlayerOrderShuffleOnStart }
                onTrueState={ () => { emitDebug.hostSkipStartShuffleTrue(); } }
                onFalseState={ () => { emitDebug.hostSkipStartShuffleFalse(); } }
              />


              <SliderOption
                title="Number of Tiles Override"
                min={0}
                max={72}
                value={ this.props.options.numTilesOverride ? this.props.options.numTilesOverride : 0 }
                onValueChange={ (newValue) => { emitDebug.hostSetTilesNumberLimit( newValue ); } }
              />

            </div>
          )
        }
      </>
    );
  }
}
