import React from 'react';
import { dispatch, useStore } from './PopupStore';

function PopupHandler( props )
{
	const { popups } = useStore();

	const onBackdropClickedEvent = () =>
	{
		closeTopPopup();
	};

	const closeTopPopup = () => 
	{
		if( !popups || popups.length == 0 )
		{
			return;
		}

		let topPopupData = popups[ popups.length - 1 ];

		dispatch({ type: 'REMOVE_POPUP', popupId: topPopupData.id });
	};

	const closePopupWithID = ( popupID ) => 
	{
		if( !popups || popups.length == 0 )
		{
			return;
		}

		dispatch({ type: 'REMOVE_POPUP', popupId: popupID });
	};

	return (
		
			<>
				<div
					className={"fade popup-modal-backdrop " + (popups.length > 0 ? "show" : "") }
					onClick={onBackdropClickedEvent}
					style={{ display: (popups.length > 0 ? "block" : "none") }}
					>
				</div>

			    <div
			    	className={"fade popup-modal " + (popups.length > 0 ? "show" : "") }
			    >
			      {popups.map((t) => {


			      	const newPopupElement = React.cloneElement(t.popupElement, {
					  close: () => { closePopupWithID( t.id ); }
					});
			        
			        return (
			          
						<div key={t.id} className={"popup-modal-container"} >
							<div className={"popup-modal-content"}>
								{ newPopupElement }
							</div>
						</div>
			        );
			      })}
			    </div>
		    </>

  	);
}
export default PopupHandler;
