import React from 'react';
import ErrorHandler from '../ErrorHandler';
import { Navigate } from "react-router-dom";
import { Col } from 'react-bootstrap';
import Button from '../Utils/Button';
import { emit } from '../Socket/game.Emitters';
import JoinRoom from './Lobby.Join';
import { gameStateManager } from '../../App';
import { loggedInTokenData, WaitUntilLoggedIn } from '../Core/LoginManager'
import PageContainerWithLogo from '../Core/PageContainerWithLogo';
import axios from 'axios';
import { restUrl } from '../../env';
import toast from 'react-hot-toast';

export default class Lobby extends React.Component
{
  state = {
    error: '',
    rooms: []
  };

  async componentDidMount()
  {
    await WaitUntilLoggedIn( async () =>
    {
        await this.updateRoomsList();
    });
  }

  updateRoomsList = async () =>
  {
    const response = await axios.post(`${restUrl}/lobby/rooms`, { tokenData: loggedInTokenData });

    // console.log("updateRoomsList() response : ", response);

    this.setState({ rooms: response.data });
  }

  onJoinRoom ( data, tokenData )
  {
    const { roomId } = data;

    gameStateManager.joinExistingRoomSocket( tokenData, roomId, () => { this.forceUpdate(); }, this.onServerErrorReceived );
  }

  onCreateNewRoom ( tokenData )
  {
    gameStateManager.createNewRoomSocket( tokenData, () => { this.forceUpdate(); }, this.onServerErrorReceived );
  }

  onServerErrorReceived = errorData =>
  {
    // this.setState({ error: errorData });

    toast.error(errorData.content);
  }

  resetError = () =>
  {
    this.setState({ error: '' });
    this.updateRoomsList();
  };


  render()
  {
    const { error, rooms } = this.state;
   
    if (error)
    {
      emit.closeConnection();
      return <ErrorHandler redirectUrl="/" error={error} resetError={this.resetError} />;
    }


    if ( gameStateManager.socketConnectedRoomId )
    {
      return (
        <Navigate to={"/#" + gameStateManager.socketConnectedRoomId } replace={true} />
      );
    }

    return (
        <PageContainerWithLogo>
          
          <Col xs="3">
            <div className="panel">
              
            </div>
          </Col>
          <Col>
            <div className="panel">

              <JoinRoom rooms={rooms} updateRoomsList={ this.updateRoomsList } onJoinRoom={ (data) => {
                this.onJoinRoom(data, loggedInTokenData);
              } } />

              <Button onClick={ () => {
                this.onCreateNewRoom( loggedInTokenData );
              } } >
                Create Room
              </Button>

            </div>

          </Col>
          <Col xs="3">
            <div className="panel">
              Lobby Chat
            </div>
          </Col>
          
        </PageContainerWithLogo>
    );
  }
}
